import styled from 'styled-components';

const handleBackgroundColor = status => {
  switch (status) {
    case 'IN_PROGRESS':
      return '#FEEFC3';
    case 'DELIVERED':
      return '#D1EABF';
    case 'DELIVERED_AND_PAID':
      return '#D0E2FA';
    case 'CANCELED':
      return '#F3BCCD';
    default:
      return '';
  }
};

const handleBorderColor = status => {
  switch (status) {
    case 'IN_PROGRESS':
      return '#FED231';
    case 'DELIVERED':
      return '#7ED32A';
    case 'DELIVERED_AND_PAID':
      return '#1A73E8';
    case 'CANCELED':
      return '#F5325B';
    default:
      return '';
  }
};

const StatusTag = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 13px;
  padding: 3px;
  font-size: ${props => props.theme.font.xxs};
  background-color: ${({ status }) => handleBackgroundColor(status)};
  border: 1px solid ${({ status }) => handleBorderColor(status)};
`;

export default StatusTag;
