import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import getClient from './graphql/getClient';
import updateClient from './graphql/updateClient';
import { withRouter } from 'react-router-dom';

import {
  Container,
  HorizontalContainer,
  Label,
  RoundButton,
  FullSizeButton,
  Input,
} from '../../components';

const EditClient = props => {
  const { history } = props;
  const [client, setClient] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchClient = async () => {
      const clientId = window.localStorage.getItem('clientId');
      const { data } = await API.graphql(
        graphqlOperation(getClient, { id: clientId })
      );
      const fetchedClient = data.getClient;

      setClient(fetchedClient);
    };

    fetchClient();
  }, []);

  const isCompleteData = () => {
    const { name, address, suburb, zipCode, phone_number } = client;

    if (name && address && suburb && zipCode.length === 5) {
      if (phone_number.length === 10) {
        return 'completeData';
      } else return 'incompletePhoneNumber';
    }

    return 'incompleteData';
  };

  const goToClients = () => {
    history.push('/clients');
  };

  const goToInfoClient = () => {
    history.push('/info-client');
  };

  const submitForm = async event => {
    event.preventDefault();

    const inputObject = {
      input: {
        id: client.id,
        name: client.name,
        address: client.address,
        suburb: client.suburb,
        reference: client.reference ? client.reference : null,
        zipCode: client.zipCode,
        phone_number: client.phone_number,
      },
    };

    if (isCompleteData() === 'completeData') {
      try {
        const { data } = await API.graphql(
          graphqlOperation(updateClient, inputObject)
        );

        if (data) {
          goToClients();
        }
      } catch (error) {
        console.log(error);
      }
    } else if (isCompleteData() === 'incompletePhoneNumber') {
      setErrorMessage('El número telefónico debe contener 10 dígitos');
    } else {
      setErrorMessage('Complete los datos para continuar');
    }
  };

  const handleChangeString = event => {
    const { target = {} } = event;
    const { name, value } = target;
    setClient(client => ({ ...client, [name]: value.replace(/[0-9]/, '') }));
  };

  const handleChange = event => {
    const { target = {} } = event;
    const { name, value } = target;
    setClient(client => ({ ...client, [name]: value }));
  };

  const handleChangeNumber = event => {
    const { target = {} } = event;
    const { name, value } = target;

    setClient(client => ({ ...client, [name]: value.replace(/[^0-9.]/g, '') }));
  };

  return (
    <Container color="#F3F4FB" minHeight="100vh">
      <HorizontalContainer>
        <Label font="s" margin="0">
          Editar cliente
        </Label>
        <RoundButton color="secondary" onClick={goToInfoClient}>
          Cancelar
        </RoundButton>
      </HorizontalContainer>
      <form onSubmit={submitForm}>
        <Input
          type="text"
          name="name"
          margin="0 0 10px 0"
          placeholder="Nombre completo *"
          value={client.name || ''}
          onChange={handleChangeString}
        />
        <Input
          type="text"
          name="address"
          margin="0 0 10px 0"
          placeholder="Domicilio *"
          value={client.address || ''}
          onChange={handleChange}
        />
        <Input
          type="text"
          name="suburb"
          margin="0 0 10px 0"
          placeholder="Colonia *"
          value={client.suburb || ''}
          onChange={handleChangeString}
        />
        <Input
          type="text"
          name="reference"
          margin="0 0 10px 0"
          placeholder="Referencia"
          value={client.reference || ''}
          onChange={handleChange}
        />
        <Input
          type="text"
          name="zipCode"
          margin="0 0 10px 0"
          placeholder="Código postal *"
          maxLength="5"
          value={client.zipCode || ''}
          onChange={handleChangeNumber}
        />
        <Input
          type="text"
          name="phone_number"
          margin="0 0 10px 0"
          placeholder="Número de teléfono *"
          maxLength="10"
          value={client.phone_number || ''}
          onChange={handleChangeNumber}
        />
        <Label font="xs" justify="flex-start" opacity="50%" margin="10px 0">
          Campos obligatorios *
        </Label>
        <Label font="xs" justify="flex-start" color="red" margin="10px 0">
          {errorMessage}
        </Label>
        <FullSizeButton color="primary" disabled={!isCompleteData()}>
          Guardar cambios
        </FullSizeButton>
      </form>
    </Container>
  );
};

export default withRouter(EditClient);
