import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  input {
    outline: none;
  }

  button {
    border: none;
    background-color: white;
    padding: 0;
    outline: none;
    position: absolute;
    right: 35px;
  }
`;
export default InputContainer;
