const getOrder = `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      creation_date
      amount
      status
      delivery_date
      delivery_hour
      note_title
      note_content
      client {
        id
        phone_number
        address
        suburb
        zipCode
        reference
        name
        affiliation_date
        orders {
          nextToken
        }
        city {
          id
          name
          state
          country
        }
      }
      products {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
    }
  }
`;

export default getOrder;
