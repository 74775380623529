import React from 'react';
import PropTypes from 'prop-types';

import imageList from './ImageList';

const Image = props => {
  const { width, name } = props;
  return <img alt="Illustration" src={imageList[name]} width={width} />;
};

Image.propTypes = {
  width: PropTypes.string,
  name: PropTypes.string,
};

export default Image;
