import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import logoImage from '../../static/logoImage.jpeg';
import {
  Container,
  HorizontalContainer,
  UserIcon,
  CircleButton,
  Label,
  Button,
} from '../../components';

const DistributorProfile = (props) => {
  const { history } = props;

  const handleBack = () => {
    history.goBack();
  };

  function handleLogOut() {
    Auth.signOut();
    history.push('/login');
  }

  return (
    <Container color="#1A73E8">
      <HorizontalContainer>
        <CircleButton color="secondary" onClick={handleBack}>
          <ArrowBackIcon fontSize="small" />
        </CircleButton>
      </HorizontalContainer>
      <UserIcon className="no-border" width="84px" height="84px">
        <img alt="User" src={logoImage} height="50px" />
      </UserIcon>
      <Label color="white" font="n" margin="28px 0 20px 0" align="center">
        Repartidor
      </Label>
      <Label font="lg" color="white">
        Ciudad de México
      </Label>
      <Button color="white" onClick={handleLogOut}>
        Cerrar sesión
      </Button>
    </Container>
  );
};

DistributorProfile.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(DistributorProfile);
