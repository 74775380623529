import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { Wrapper } from './components';

const Dropdown = props => {
  const {
    placeholder,
    options,
    numberOfOptions = 3,
    activeOption,
    handleChange,
    margin,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <Wrapper margin={margin} numberOfOptions={numberOfOptions}>
        <button onClick={handleClick}>
          {activeOption.text || placeholder}
          {isOpen ? (
            <ArrowDropUpIcon style={{ opacity: '50%' }} />
          ) : (
            <ArrowDropDownIcon style={{ opacity: '50%' }} />
          )}
        </button>
        {isOpen && (
          <ul>
            {options.map(option => {
              const handleOptionClick = () => {
                handleChange(option);
                setIsOpen(false);
              };

              return (
                <li key={option.value} onClick={handleOptionClick}>
                  {option.text}
                </li>
              );
            })}
          </ul>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  activeOption: PropTypes.object,
  handleChange: PropTypes.func,
  margin: PropTypes.string,
};

export default Dropdown;
