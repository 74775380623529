import styled from 'styled-components';

const Label = styled.h1`
  font-size: ${props =>
    (props.font === 'xl' && props.theme.font.xl) ||
    (props.font === 'lg' && props.theme.font.lg) ||
    (props.font === 'n' && props.theme.font.n) ||
    (props.font === 'md' && props.theme.font.md) ||
    (props.font === 'sm' && props.theme.font.sm) ||
    (props.font === 's' && props.theme.font.s) ||
    (props.font === 'xs' && props.theme.font.xs) ||
    (props.font === 'xxs' && props.theme.font.xxs)};
  margin: ${props => props.margin};
  color: ${props => props.color};
  opacity: ${props => props.opacity};
  display: flex;
  text-align: ${props => props.textAlign};
  align-items: ${props => (props.align ? props.align : 'center')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
`;

export default Label;
