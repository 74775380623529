import styled from 'styled-components';

const Wrapper = styled.div`
  height: 56px;
  width: 100%;
  margin: ${props => props.margin};

  button {
    height: 56px;
    width: 100%;
    border-radius: 3px;
    padding: 0 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eaeaea;
    background-color: white;
    color: rgba(0, 0, 0, 0.5);
    outline: none;
    font-size: ${props => props.theme.font.sm};

    &:focus {
      border: 1px solid #1a73e8;
    }
  }

  ul {
    background-color: white;
    padding: 0;
    margin: 0;
    height: ${props => props.numberOfOptions * 56}px;
    overflow: auto;
    border: 1px solid #eaeaea;
    border-radius: 0 0 3px 3px;
    position: relative;
    z-index: 9;

    li {
      background-color: white;
      height: 56px;
      padding: 10px 17px;
      display: flex;
      align-items: center;
      list-style: none;
      border-bottom: 1px solid #eaeaea;
      color: rgba(0, 0, 0, 0.5);
      font-size: ${props => props.theme.font.sm};
    }
  }
`;

export default Wrapper;
