import React, { useState, useEffect } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

function PrivateRoute({ component: Component, routeUserType, ...rest }) {
  const [payloadInformation, setPayloadInformation] = useState({
    userId: '',
    userType: '',
    userLocation: '',
  });
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    handleUser();
  }, []);

  async function handleUser() {
    try {
      const {
        idToken: { payload },
      } = await Auth.currentSession();

      if (payload) {
        setPayloadInformation({
          userId: payload.sub,
          userType: payload['cognito:groups'][0],
          userLocation: payload.address.formatted,
        });
      }

      setLoaded(true);
    } catch (err) {
      setPayloadInformation({
        userId: '',
        userType: '',
        userLocation: '',
      });

      setLoaded(true);
    }
  }

  if (!isLoaded) return null;

  return (
    <Route
      {...rest}
      render={({ location, ...props }) => {
        return payloadInformation.userId &&
          routeUserType.includes(payloadInformation.userType) ? (
          <Component
            userType={payloadInformation.userType}
            userLocation={payloadInformation.userLocation}
            {...props}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

export default withRouter(PrivateRoute);
