import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  padding: 0 17px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .modal-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    overflow-y: none;

    .modal {
      width: 100%;
      background-color: white;
      border-radius: 10px;

      .modal-info {
        height: 120px;
        border-radius: 10px 10px 0 0;
        padding: 0 28px;
        color: white;
        background-color: ${props => props.theme.colors.primary};
        display: flex;
        flex-direction: column;
        justify-content: center;

        .modal-title {
          font-size: 34px;
        }
      }

      .modal-content {
        width: 100%;
        padding: 20px 28px;

        .error-message {
          color: red;
          margin-top: 0;
          display: flex;
          align-items: center;

          h3 {
            font-size: 14px;
            margin-left: 5px;
          }
        }

        .modal-buttons {
          height: 34px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-end;

          button {
            height: 34px;
            width: 102px;
            border-radius: 3px;
            outline: none;
          }

          .primary-button {
            background-color: #1a73e8;
            border: none;
            color: white;
            margin-left: 10px;
          }

          .secondary-button {
            background-color: white;
            border: 1px solid #eaeaea;
          }
        }
      }
    }
  }
`;

export default Wrapper;
