import styled from 'styled-components';

const Wrapper = styled.div`
  .file-picker {
    height: 104px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 3px;
    border: 1px solid #eaeaea;
    margin-bottom: 10px;

    label {
      opacity: 50%;
      font-size: 14px;
      margin-top: 10px;
    }

    input {
      display: none;
    }
  }
`;
export default Wrapper;
