import styled from 'styled-components';

const Wrapper = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  overflow: scroll;
  padding-left: 17px;
`;

export default Wrapper;
