const hoursOptions = [
  { text: '9:00', value: '9:00' },
  { text: '9:30', value: '9:30' },
  { text: '10:00', value: '10:00' },
  { text: '10:30', value: '10:30' },
  { text: '11:00', value: '11:00' },
  { text: '11:30', value: '11:30' },
  { text: '12:00', value: '12:00' },
  { text: '12:30', value: '12:30' },
  { text: '13:00', value: '13:00' },
  { text: '13:30', value: '13:30' },
  { text: '14:00', value: '14:00' },
  { text: '14:30', value: '14:30' },
  { text: '15:00', value: '15:00' },
  { text: '15:30', value: '15:30' },
  { text: '16:00', value: '16:00' },
  { text: '16:30', value: '16:30' },
  { text: '17:00', value: '17:00' },
  { text: '17:30', value: '17:30' },
  { text: '18:00', value: '18:00' },
  { text: '18:30', value: '18:30' },
];

export default hoursOptions;
