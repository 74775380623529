const createOrderProduct = `
mutation CreateOrderProduct($input: CreateOrderProductInput!) {
  createOrderProduct(input: $input) {
    id
    orderId
    productId
  }
}
`;

export default createOrderProduct;
