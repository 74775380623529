import React from 'react';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Label } from '../../components';
import { Wrapper } from './components';

const Modal = props => {
  const {
    title,
    subTitle,
    handleClose,
    handleSave,
    errorMessage,
    showError,
    saveButton,
  } = props;

  return (
    <Wrapper>
      <div className="modal-container">
        <div className="modal">
          <div className="modal-info">
            <Label font="s" justify="left" margin="0" opacity="50%">
              {subTitle}
            </Label>
            <div className="modal-title">{title}</div>
          </div>
          <div className="modal-content">
            {showError && (
              <div className="error-message">
                <ErrorOutlineIcon fontSize="small" />
                <h3>{errorMessage}</h3>
              </div>
            )}
            {props.children}
            <div className="modal-buttons">
              <button className="secondary-button" onClick={handleClose}>
                Cancelar
              </button>
              {saveButton && (
                <button className="primary-button" onClick={handleSave}>
                  Guardar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  errorMessage: PropTypes.string,
  showError: PropTypes.bool,
  saveButton: PropTypes.bool,
};

export default Modal;
