import React from 'react';

import { Modal, Label } from '../../../../components';

const AddressModal = props => {
  const { setShowAddressModal, client } = props;

  return (
    <Modal
      title="Domicilio"
      saveButton={false}
      handleClose={() => setShowAddressModal(false)}
    >
      <Label font="s" margin="0" justify="flex-start" opacity="50%">
        {client.address}
      </Label>
      <Label font="s" margin="5px 0" justify="flex-start" opacity="50%">
        Colonia: {client.suburb}
      </Label>
      <Label font="s" margin="5px 0" justify="flex-start" opacity="50%">
        Código postal: {client.zipCode}
      </Label>
      {client.reference && (
        <>
          <Label font="s" margin="10px 0" justify="flex-start">
            Referencias:
          </Label>
          <Label font="s" margin="10px 0" justify="flex-start" opacity="50%">
            {client.reference}
          </Label>
        </>
      )}
      <Label font="s" margin="5px 0" justify="flex-start" opacity="50%">
        Teléfono: {client.phone_number}
      </Label>
    </Modal>
  );
};

export default AddressModal;
