import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import { Money } from '../../../../types';
import { ProductsContainer } from './components';
import { Modal, Label } from '../../../../components';

import listOrderProducts from '../../graphql/listOrderProducts';

const ProductsModal = props => {
  const { orderId, setShowProductsModal } = props;
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchOrderProducts = async () => {
      try {
        const { data } = await API.graphql(
          graphqlOperation(listOrderProducts, {
            filter: { orderId: { eq: orderId } },
          })
        );

        if (data) {
          const newProducts = data.listOrderProducts.items.map(item => {
            let newProduct = item.product;
            newProduct.items = item.productItems;
            return newProduct;
          });
          setProducts(newProducts);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrderProducts();
  }, [orderId]);

  const handleCloseModal = () => {
    setShowProductsModal(false);
  };

  return (
    <Modal
      title={products.lenght ? `${products.lenght} productos` : 'Productos'}
      subTitle="Productos"
      handleClose={handleCloseModal}
      saveButton={false}
    >
      <ProductsContainer>
        {products &&
          products.map(product => {
            return (
              <div className="product-row" key={product.id}>
                <div className="row">
                  <Label font="s" margin="0">
                    {product.name}
                  </Label>
                  <Label font="xxs" margin="0" className="product-price">
                    {new Money(product.price).format()}
                  </Label>
                </div>
                <div className="row">
                  <Label font="sm" align="left" margin="0" opacity="50%">
                    {product.quantity}
                  </Label>
                  <Label font="sm" margin="0" opacity="50%">
                    {product.items}
                  </Label>
                </div>
              </div>
            );
          })}
      </ProductsContainer>
    </Modal>
  );
};

ProductsModal.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  setShowProductsModal: PropTypes.func,
};

export default ProductsModal;
