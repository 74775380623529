import styled from 'styled-components';

const NavBarContainer = styled.div`
  width: 100%;
  height: 58px;
  background-color: white;
  padding: 0 17px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0.5 3px 0px #0000001a;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;

  button {
    width: 58px;
    height: 41px;
    display: flex;
    border: none;
    background-color: white;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
  }
`;

export default NavBarContainer;
