import styled from 'styled-components';

const Container = styled.div`
  padding: ${props =>
    props.padding ? props.padding : props.theme.padding.mobile};
  min-height: ${props => (props.minHeight ? props.minHeight : '150vh')};
  position: relative;
  margin: ${props => props.margin};
  background-color: ${props => (props.color ? props.color : '#f3f4fb')};

  &.product-list {
    margin-bottom: 100px;
  }
`;

export default Container;
