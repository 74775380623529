import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import {
  Login,
  Cities,
  CreateClient,
  CreateOrder,
  Orders,
  Clients,
  CreateCity,
  InfoClient,
  EditClient,
  Overview,
  AdminProfile,
  NotFound,
  OrdersDistributor,
  OrderDetails,
  DistributorProfile,
  OrderDetailsDistributor,
  NewDeliveryMan,
} from './views';

import { Intro } from './components';

import PrivateRoute from './hoc/PrivateRoute';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Intro />} />
        <Route path="/login" render={() => <Login />} />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/cities"
          exact
          component={Cities}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/admin-profile"
          exact
          component={AdminProfile}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/create-city"
          exact
          component={CreateCity}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/overview"
          exact
          component={Overview}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/clients"
          exact
          component={Clients}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/create-client"
          exact
          component={CreateClient}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/info-client"
          exact
          component={InfoClient}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/edit-client"
          exact
          component={EditClient}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/create-order"
          exact
          component={CreateOrder}
        />
        <PrivateRoute
          routeUserType={['Admin']}
          path="/orders"
          exact
          component={Orders}
        />
        <PrivateRoute
          path="/orders-distributor"
          routeUserType={['Admin', 'DeliveryMan']}
          exact
          component={OrdersDistributor}
        />
        <PrivateRoute
          path="/distributor-profile"
          routeUserType={['Admin', 'DeliveryMan']}
          exact
          component={DistributorProfile}
        />
        <PrivateRoute
          path="/order-details-distributor"
          routeUserType={['Admin', 'DeliveryMan']}
          exact
          component={OrderDetailsDistributor}
        />
        <PrivateRoute
          path="/order-details"
          routeUserType={['Admin']}
          exact
          component={OrderDetails}
        />
        <Route
          path="/new-delivery-man"
          routeUserType={['Admin']}
          exact
          component={NewDeliveryMan}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
