import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import { Container, Label, Input, Button } from '../../components';
import { InputContainer } from './components';

function NewDeliveryMan() {
  const [values, setValues] = useState({
    username: '',
    password: '',
    email: '',
    location: '',
    authCode: '',
    showConfirmation: false,
  });

  const [inputType, setInputType] = useState('password');
  const [errorMessage, setErrorMessage] = useState('');

  function handleState(key, value) {
    setValues({
      ...values,
      [key]: value,
    });
  }

  function handleSignUp() {
    const { username, password, email, location } = values;

    Auth.signUp({
      username,
      password,
      attributes: {
        email,
        address: location,
      },
    })
      .then(() => setValues({ ...values, showConfirmation: true }))
      .catch((err) => {
        setErrorMessage('El nombre de usuario no se pudo crear. Asegúrese de que ... ingresó entre 1 y 128 caracteres');
        console.error('error signing up: ', err);
      });
  }

  const showPassword = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  function confirmSignUp() {
    Auth.confirmSignUp(values.username, values.authCode)
      .then(() => console.log('Delivery man created!'))
      .catch((err) => console.error('error confirming signing up: ', err));
  }

  return !values.showConfirmation ? (
    <Container minHeight="100vh">      
      <Label font="xl" align="center" margin="70px 0 40px 0">
        Registro 
      </Label>
        <Input
          margin="0 0 10px 0"
          placeholder = "Nombre de usuario"
          value={values.username}
          id="username"
          onChange={(event) => handleState('username', event.target.value)}
        />
        <InputContainer>   
        <Input
          margin="0 0 10px 0"
          placeholder = "Password"
          value={values.password}
          id="password"
          type={inputType}
          onChange={(event) => handleState('password', event.target.value)}
        />
        <button type="button" onClick={showPassword}>
            <RemoveRedEyeIcon className="icon" style={{ opacity: '50%' }} />
        </button>
        </InputContainer>       
        <Input
          placeholder="Email"
          margin="0 0 10px 0"
          value={values.email}
          id="email"
          type="email"
          onChange={(event) => handleState('email', event.target.value)}
        />
        <Input
          margin="0 0 10px 0"
          placeholder="Ubicación"
          value={values.location}
          id="location"
          onChange={(event) => handleState('location', event.target.value)}
        />
          {errorMessage && (
          <Label font="xs" margin="10px 0" color="red">
            {errorMessage}
          </Label>
        )}
        <Button color="#1A73E8" fontColor="white" type="button" onClick={handleSignUp}>
          Registrar
        </Button>
    </Container>
  ) : (
    <Container minHeight="100vh">
        <Label font="xl" align="center" margin="70px 0 40px 0">
          Confirmación
        </Label>
        <Input
          placeholder="Ingresa codigo de confirmación"
          value={values.authCode}
          id="authCode"
          onChange={(event) => handleState('authCode', event.target.value)}
        />
        <Button color="#1A73E8" fontColor="white" type="button" onClick={confirmSignUp}>
          Confirmar Codigo
        </Button>
     
    </Container>
  );
}

export default NewDeliveryMan;
