import styled from 'styled-components';

const Button = styled.button`
  background-color: ${props => props.color};
  color: ${props => props.fontColor};
  border: 1px solid #eaeaea;
  border-radius: 3px;
  outline: none;
  height: 48px;
  width: calc(100% - 34px);
  padding: 0;
  font-size: 14px;
  position: fixed;
  bottom: 50px;
  left: 17px;
  margin: ${props => props.margin};
`;

export default Button;
