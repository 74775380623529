import styled from 'styled-components';

const FullSizeButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  outline: none;
  height: 48px;
  width: 100%;
  padding: 0;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
`;

export default FullSizeButton;
