import styled from 'styled-components';

const TextArea = styled.textarea`
  resize: none;
  border-radius: 3px;
  width: 100%;
  height: 153px;
  border: 1px solid ${props => props.theme.colors.lightGray};
  outline: none;
  padding: 17px;

  &:focus {
    border: 1px solid ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: white;
  }
`;

export default TextArea;
