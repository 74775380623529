import styled from 'styled-components';

const ImageContainer = styled.div`
  width: 100%;
  height: 187px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${props => props.margin};
`;

export default ImageContainer;
