import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Modal } from '../../../../components';
import { errorMessages, hoursOptions } from '../../../../utils';

const SelectTimeModal = props => {
  const { setShowTimeModal, setTime } = props;
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeHour, setActiveHour] = useState({});

  const handleHourChange = hour => setActiveHour(hour);

  const handleSaveTime = () => {
    if (activeHour.text) {
      setTime(activeHour.value);
      setShowTimeModal(false);
    } else {
      setErrorMessage(errorMessages.incompleteData);
      setShowErrorMessage(true);
    }
  };

  const handleCloseModal = () => {
    setShowTimeModal(false);
  };

  return (
    <Modal
      title={activeHour.text || '00:00'}
      subTitle="Horario"
      handleClose={handleCloseModal}
      handleSave={handleSaveTime}
      showError={showErrorMessage}
      errorMessage={errorMessage}
      saveButton={true}
    >
      <Dropdown
        margin="0 0 10px 0"
        options={hoursOptions}
        placeholder="Horarios"
        activeOption={activeHour}
        handleChange={handleHourChange}
      />
    </Modal>
  );
};

SelectTimeModal.propTypes = {
  setShowTimeModal: PropTypes.func,
  setTime: PropTypes.func,
};

export default SelectTimeModal;
