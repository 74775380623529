import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import listCategories from './graphql/listCategories';
import PropTypes from 'prop-types';

import { Wrapper, Category } from './components';

const CategoriesNavBar = props => {
  const { activeCategory, setActiveCategory } = props;
  const [categories, setCategories] = useState([{ id: '13', type: 'Todos' }]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await API.graphql(
          graphqlOperation(listCategories, { limit: 999 })
        );

        if (data) {
          const { items } = data.listCategorys;
          setCategories(prevCategories => [...prevCategories, ...items]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <Wrapper>
      {categories &&
        categories.map(category => {
          return (
            <Category
              key={category.id}
              active={activeCategory === category.type}
              onClick={() => setActiveCategory(category.type)}
            >
              <div>{category.type}</div>
            </Category>
          );
        })}
    </Wrapper>
  );
};

CategoriesNavBar.propTypes = {
  activeCategory: PropTypes.string,
  setActiveCategory: PropTypes.func,
};

export default CategoriesNavBar;
