// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b52254a2-a057-4514-8f61-ed3d1e5268d0",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_MM1IEjWhC",
    "aws_user_pools_web_client_id": "5jtkcbp0l4f2f36s3vc5nti1kt",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://nwtpyyopt5etpgihkniavhswvy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "beitbucket210400-master",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
