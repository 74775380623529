import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  Container,
  Label,
  ImageContainer,
  Image,
} from '../../components';

const NotFound = props => {
  const { history } = props;

  const handleClick = () => {
    history.goBack();
  };

  return (
    <Container color="#F3F4FB" minHeight="100vh">
      <ImageContainer margin="80px 0 0 0">
        <Image name="notFound" width="210px" />
      </ImageContainer>
      <Label font="n" align="center" margin="40px 0 0 0">
        Página no encontrada
      </Label>
      <Button color="white" onClick={handleClick}>
        Ir a Inicio
      </Button>
    </Container>
  );
};

export default withRouter(NotFound);
