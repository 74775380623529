import styled from 'styled-components';

const UserIcon = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 9999px;
  border: 1px solid #d5e7ff;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin};

  &.no-border {
    border: none;
  }

  img {
    width: ${props => props.imgWidth};
    height: ${props => props.imgHeight};
    border-radius: 9999px;
  }
`;

export default UserIcon;
