const currentYear = new Date().getFullYear();

const yearOptions = [
  { text: (currentYear - 1).toString(), value: currentYear - 1 },
  { text: currentYear.toString(), value: currentYear },
  { text: (currentYear + 1).toString(), value: currentYear + 1 },
  { text: (currentYear + 2).toString(), value: currentYear + 2 },
  { text: (currentYear + 3).toString(), value: currentYear + 3 },
  { text: (currentYear + 4).toString(), value: currentYear + 4 },
  { text: (currentYear + 5).toString(), value: currentYear + 5 },
  { text: (currentYear + 6).toString(), value: currentYear + 6 },
];

export default yearOptions;
