import React, { Fragment, useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import getClient from './graphql/getClient';
import { withRouter } from 'react-router-dom';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import logoImage from '../../static/logoImage.jpeg';
import { Money, DateUserFormatter, StatusFormatter } from '../../types';

import {
  Container,
  HorizontalContainer,
  Label,
  RoundButton,
  CircleButton,
  Card,
  UserIcon,
  BottomContainer,
  NoDataMessage,
  Loading,
  StatusTag,
} from '../../components';

const InfoClient = props => {
  const { history } = props;
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientOrders, setClientOrders] = useState([]);
  const clientId = window.localStorage.getItem('clientId');

  useEffect(() => {
    const fetchClient = async () => {
      setLoading(true);

      try {
        const { data } = await API.graphql(
          graphqlOperation(getClient, { id: clientId })
        );
        const fetchedClient = await data.getClient;

        if (data) {
          setLoading(false);
        }

        setClient(fetchedClient);
        const clientOrdersFetched = fetchedClient.orders.items;
        setClientOrders(clientOrdersFetched);
      } catch (error) {
        console.log(error);
      }
    };

    fetchClient();
  }, [clientId]);

  const goToCreateOrder = () => {
    history.push('./create-order');
  };

  const goToEditClient = () => {
    history.push('./edit-client');
  };

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  const goToClients = () => {
    history.push('./clients');
  };

  const getTotalOfSales = () => {
    const totalOfSales = clientOrders.reduce(
      (total, order) => total + order.amount,
      0
    );
    return Number(totalOfSales);
  };

  return (
    <Fragment>
      <Container color="#F3F4FB" minHeight="100vh">
        <HorizontalContainer>
          <CircleButton color="secondary" onClick={goToClients}>
            <ArrowBackIcon fontSize="small" />
          </CircleButton>
          <UserIcon
            width="32px"
            height="32px"
            imgWidth="20px"
            imgHeight="17px"
            onClick={goToAdminProfile}
          >
            <img alt="User" src={logoImage} />
          </UserIcon>
        </HorizontalContainer>
        <HorizontalContainer>
          <Label font="md">Pedidos</Label>
          <RoundButton color="primary" onClick={goToCreateOrder}>
            <AddIcon style={{ marginRight: '5px' }} />
            Pedido
          </RoundButton>
        </HorizontalContainer>
        {loading && <Loading />}
        <Card width="100%">
          <div className="card-top no-border">
            <UserIcon
              width="32px"
              height="32px"
              imgWidth="20px"
              imgHeight="17px"
            >
              <img alt="User" src={logoImage} height="32px" />
            </UserIcon>
            <Label font="s" margin="0 0 0 6px">
              {client.name}
            </Label>
            <div className="icon-card" onClick={goToEditClient}>
              <EditIcon fontSize="small" style={{ opacity: '50%' }} />
            </div>
          </div>
          {clientOrders.length > 0 &&
            clientOrders.map(order => {
              return (
                <div className="card-order" key={order.id}>
                  <Label font="xxs" margin="0" justify="flex-start">
                    {new DateUserFormatter(order.delivery_date).format()}
                  </Label>
                  <Label
                    font="xxs"
                    margin="0"
                    opacity="50%"
                    justify="flex-start"
                  >
                    {new Money(order.amount).format()}
                  </Label>
                  <StatusTag status={order.status}>
                    {new StatusFormatter(order.status).format()}
                  </StatusTag>
                </div>
              );
            })}
        </Card>
        {clientOrders.length === 0 && !loading && (
          <NoDataMessage>
            <div className="icon-container">
              <MotorcycleIcon style={{ opacity: '50%' }} />
            </div>
            <Label font="md" opacity="50%" textAlign="center">
              No se han creado pedidos
            </Label>
          </NoDataMessage>
        )}
        <BottomContainer justify="space-between" bottom="0">
          <Label font="n" margin="0">
            Total en ventas
          </Label>
          <Label font="n" margin="0" opacity="50%">
            {clientOrders.length ? new Money(getTotalOfSales()).format() : '0'}
          </Label>
        </BottomContainer>
      </Container>
    </Fragment>
  );
};

export default withRouter(InfoClient);
