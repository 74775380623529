import styled from 'styled-components';

const Category = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    white-space: nowrap;
    height: 20px;
    font-size: ${props => props.theme.font.sm};
    margin-right: 30px;
    color: ${props => (props.active ? '#1A73E8' : 'rgba(0, 0, 0, 0.5)')};
    text-decoration: ${props => (props.active ? 'underline' : 'none')};
  }
`;

export default Category;
