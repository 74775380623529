import React from 'react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import logoImage from '../../static/logoImage.jpeg';
import {
  Button,
  Container,
  CircleButton,
  Label,
  UserIcon,
} from '../../components';
import { CenterContainer } from './components';

const AdminProfile = props => {
  const { history } = props;

  const handleBack = () => {
    history.goBack();
  };

  const handleLogOut = () => {
    Auth.signOut();
    history.push('/login');
  };

  return (
    <Container color="#F3F4FB">
      <CircleButton color="secondary" onClick={handleBack}>
        <ArrowBackIcon fontSize="small" />
      </CircleButton>
      <CenterContainer margin="20px 0 0 0">
        <UserIcon className="no-border" width="84px" height="84px">
          <img alt="User" src={logoImage} height="50px" />
        </UserIcon>
      </CenterContainer>
      <Label font="n" align="center" margin="30px 0">
        Usuario Administrador
      </Label>
      <Button color="white" onClick={handleLogOut}>
        Cerrar sesión
      </Button>
    </Container>
  );
};

export default withRouter(AdminProfile);
