import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { Money } from '../../types';
import { Wrapper } from './components';
import { Label } from '../../components';

const ProductCard = props => {
  const { product: productProps, addItem, subtractItem } = props;
  const [product, setProduct] = useState(productProps);
  const { name, items, price, quantity } = product;

  const handleAddItem = () => {
    addItem(product);

    const newProduct = { ...product, items: items + 1 };
    setProduct(newProduct);
  };

  const handleSubtractItem = () => {
    subtractItem(product);

    const newProduct = { ...product, items: items - 1 };

    if (newProduct.items >= 0) {
      setProduct(newProduct);
    }
  };

  return (
    <Wrapper>
      <div className="image-container" />
      <div className="product-description">
        <div className="description">
          <Label font="sm" margin="0">
            {name}
          </Label>
          <Label font="xxs" color="#1A73E8" margin="0">
            {new Money(price).format()}
          </Label>
        </div>
        <div className="description">
          <Label font="sm" margin="0 0 20px 0" opacity="50%">
            {quantity}
          </Label>
        </div>
        <div className="add-product">
          <div className="buttons">
            <button onClick={handleSubtractItem}>
              <RemoveIcon fontSize="small" style={{ opacity: '50%' }} />
            </button>
            <button onClick={handleAddItem}>
              <AddIcon fontSize="small" style={{ color: '#1A73E8' }} />
            </button>
          </div>
          <div className="product-units">
            <Label font="sm" opacity="50%" margin="0">
              {items > 0 ? `${items} items` : '0 items'}
            </Label>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
  addItem: PropTypes.func,
  subtractItem: PropTypes.func,
};

export default ProductCard;
