import styled from 'styled-components';

const ProductsContainer = styled.div`
  width: 100%;
  height: 256px;
  overflow-y: scroll;

  .product-row {
    height: 53px;
    margin-bottom: 10px;

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .product-price {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

export default ProductsContainer;
