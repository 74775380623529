import styled from 'styled-components';

const Wrapper = styled.div`
  height: 117px;
  display: flex;
  border-radius: 10px;
  background-color: white;
  padding: 18px;
  box-shadow: 0px 0.5px 3px 0px #0000001a;
  margin-bottom: 10px;

  .image-container {
    width: 84px;
    height: 100%;
    background-color: ${props => props.theme.colors.primary};
  }

  .product-description {
    margin-left: 10px;

    .description {
      width: 197px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .add-product {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .buttons {
        width: 56px;
        display: flex;
        justify-content: space-between;

        button {
          width: 20px;
          height: 20px;
          border: none;
          background-color: white;
          padding: 0;
          outline: none;
        }
      }
    }
  }
`;

export default Wrapper;
