import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import PlaceIcon from '@material-ui/icons/Place';
import AssessmentIcon from '@material-ui/icons/Assessment';
import WavesIcon from '@material-ui/icons/Waves';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import logoImage from '../../static/logoImage.jpeg';
import {
  CircleButton,
  HorizontalContainer,
  Label,
  UserIcon,
} from '../../components';
import { CardMenu, MenuContainer } from './components';

const OptionButton = props => {
  const { Icon, label, value, redirectTo, activeRoute } = props;

  const handleClick = () => {
    redirectTo(value);
  };

  return (
    <button
      className={activeRoute === value ? 'active menu-route' : 'menu-route'}
      onClick={handleClick}
    >
      <Icon fontSize="small" />
      <h3>{label}</h3>
    </button>
  );
};

const Menu = props => {
  const { history, setShowMenu } = props;

  const activeRoute = history.location.pathname.slice(1);

  const redirectTo = route => {
    history.push(`./${route}`);
  };

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  const handleClose = () => {
    setShowMenu(prevState => !prevState);
  };

  return (
    <MenuContainer>
      <HorizontalContainer padding="0 17px">
        <CircleButton color="secondary" onClick={handleClose}>
          <CloseIcon />
        </CircleButton>
        <UserIcon
          width="32px"
          height="32px"
          imgWidth="20px"
          imgHeight="17px"
          onClick={goToAdminProfile}
        >
          <img alt="User" src={logoImage} />
        </UserIcon>
      </HorizontalContainer>
      <CardMenu>
        <div className="menu-title">
          <Label font="md" margin="0" color="#1A73E8">
            Beit.co
          </Label>
        </div>
        <OptionButton
          label="Ciudades"
          activeRoute={activeRoute}
          value="cities"
          Icon={PlaceIcon}
          redirectTo={redirectTo}
        />
        <OptionButton
          label="Overview"
          activeRoute={activeRoute}
          value="overview"
          Icon={AssessmentIcon}
          redirectTo={redirectTo}
        />
        <OptionButton
          label="Clientes"
          activeRoute={activeRoute}
          value="clients"
          Icon={WavesIcon}
          redirectTo={redirectTo}
        />
        <OptionButton
          label="Ordenes"
          activeRoute={activeRoute}
          value="orders"
          Icon={MotorcycleIcon}
          redirectTo={redirectTo}
        />
      </CardMenu>
    </MenuContainer>
  );
};

Menu.propTypes = {
  setShowMenu: PropTypes.func.isRequired,
};

export default withRouter(Menu);
