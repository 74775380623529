function Money(amount) {
  this.amount = amount;

  const format = () => {
    const currency = 'USD';
    const format = 'en-US';

    return Number(this.amount).toLocaleString(format, {
      style: 'currency',
      currency
    });
  };

  return { format };
}

export default Money;
