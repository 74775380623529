import React, { Fragment, useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import listClients from './graphql/listClients';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';

import logoImage from '../../static/logoImage.jpeg';
import {
  Container,
  StickyContainer,
  HorizontalContainer,
  Label,
  RoundButton,
  UserIcon,
  NavBar,
  Card,
  Menu,
  Loading,
  NoDataMessage,
} from '../../components';

const Clients = props => {
  const { history } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [clients, setClients] = useState([]);
  const [listOfClients, setListOfClients] = useState([]);
  const [status, setStatus] = useState('idle');
  const isLoading = status === 'pending';

  const city = {
    id: window.localStorage.getItem('cityId'),
    name: window.localStorage.getItem('cityName'),
  };

  useEffect(() => {
    const fetchClients = async () => {
      setStatus('pending');

      try {
        const { data } = await API.graphql(
          graphqlOperation(listClients, { limit: 999 })
        );
        const { items: clients } = data.listClients;

        if (clients.length > 0) {
          const filteredClients = clients.filter(
            client => client.city.id === city.id
          );

          setClients(filteredClients);
          setListOfClients(filteredClients);
        }
        setStatus('resolved');
      } catch (error) {
        setStatus('rejected');
      }
    };

    fetchClients();
  }, [city.id]);

  const handleFilterClients = event => {
    const {
      target: { value },
    } = event;

    if (value) {
      const lowerCaseValue = value.toLowerCase();
      const filteredClients = clients.filter(client => {
        const lowerCaseClient = client.name.toLowerCase();
        return lowerCaseClient.startsWith(lowerCaseValue);
      });
      setListOfClients(filteredClients);
    } else {
      setListOfClients(clients);
    }
  };

  const goToClientDetails = client => {
    window.localStorage.setItem('clientId', client.id);
    history.push('./info-client');
  };

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  const openMenu = () => {
    setShowMenu(prevState => !prevState);
  };

  const createNewClient = () => {
    history.push('./create-client');
  };

  return (
    <Fragment>
      {showMenu && <Menu setShowMenu={setShowMenu} />}
      <StickyContainer>
        <HorizontalContainer>
          <RoundButton color="secondary" onClick={openMenu}>
            Menú
          </RoundButton>
          <UserIcon
            width="32px"
            height="32px"
            imgWidth="20px"
            imgHeight="17px"
            onClick={goToAdminProfile}
          >
            <img alt="User" src={logoImage} />
          </UserIcon>
        </HorizontalContainer>
        <HorizontalContainer>
          <Label font="md" margin="0">
            {city.name}
          </Label>
          <RoundButton color="primary" onClick={createNewClient}>
            <AddIcon fontSize="small" style={{ marginRight: '5px' }} />
            Cliente
          </RoundButton>
        </HorizontalContainer>
      </StickyContainer>
      <Container
        color="#F3F4FB"
        minHeight={listOfClients.length ? '100vh' : 'calc(100vh - 129px)'}
        margin="0 0 50px 0"
      >
        {isLoading && <Loading />}
        <Card width="100%" padding="0">
          <Fragment>
            {clients.length > 0 && (
              <div className="card-top">
                <SearchIcon
                  fontSize="small"
                  style={{ opacity: '50%', marginRight: '5px' }}
                />
                <input
                  name="filter"
                  className="search-bar"
                  placeholder="Buscar por nombre"
                  onChange={handleFilterClients}
                />
              </div>
            )}
            {listOfClients &&
              listOfClients.map(client => {
                return (
                  <div
                    className="card-top"
                    key={client.id}
                    onClick={() => goToClientDetails(client)}
                  >
                    <UserIcon
                      width="32px"
                      height="32px"
                      imgWidth="20px"
                      imgHeight="17px"
                    >
                      <img alt="User" src={logoImage} />
                    </UserIcon>
                    <Label font="s" margin="0 0 0 6px">
                      {client.name}
                    </Label>
                  </div>
                );
              })}
          </Fragment>
        </Card>
        {clients.length === 0 && !isLoading && (
          <NoDataMessage>
            <div className="icon-container">
              <PersonAddIcon style={{ opacity: '50%' }} />
            </div>
            <Label font="md" opacity="50%" textAlign="center">
              No se han creado clientes
            </Label>
          </NoDataMessage>
        )}
        <NavBar />
      </Container>
    </Fragment>
  );
};

export default withRouter(Clients);
