const listProducts = `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
      }
      nextToken
    }
  }
`;

export default listProducts;
