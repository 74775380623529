import React, { Fragment, useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import getClient from './graphql/getClient';
import listProducts from './graphql/listProducts';
import { withRouter } from 'react-router-dom';

import { useOrder } from '../../hooks';
import { Money } from '../../types';
import logoImage from '../../static/logoImage.jpeg';
import {
  Container,
  HorizontalContainer,
  StickyContainer,
  Label,
  RoundButton,
  BottomContainer,
  CategoriesNavBar,
  ProductCard,
  UserIcon,
  FullSizeButton,
  Loading,
  SearchBar,
} from '../../components';
import { ShoppingCart, OrderDetails } from './components';

const CreateOrder = props => {
  const { history } = props;
  const [productsList, setProductsList] = useState([]);
  const [activeCategory, setActiveCategory] = useState('Todos');
  const [shoppingCart, setShoppingCart] = useState(false);
  const [orderDetails, setOrderDetails] = useState(false);
  const [client, setClient] = useState({});
  const [products, setProducts] = useState([]);
  const [status, setStatus] = useState('idle');
  const isLoading = status === 'pending';
  const order = useOrder();

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const clientId = window.localStorage.getItem('clientId');
        const { data } = await API.graphql(
          graphqlOperation(getClient, { id: clientId })
        );

        if (data) {
          setClient(data.getClient);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchProducts = async () => {
      setStatus('pending');
      try {
        const { data } = await API.graphql(
          graphqlOperation(listProducts, { limit: 200 })
        );

        if (data) {
          const { items: fetchedProducts } = data.listProducts;

          setProducts(fetchedProducts);
          setProductsList(fetchedProducts);
        }

        setStatus('resolved');
      } catch (error) {
        setStatus('rejected');
      }
    };

    fetchClient();
    fetchProducts();
  }, []);

  const filter = () => {
    if (activeCategory === 'Todos') {
      setProductsList(products);
    } else {
      const productsByCategory = products.filter(
        product => product.category.type === activeCategory
      );

      setProductsList(productsByCategory);
    }
  };

  useEffect(() => {
    filter();
  }, [activeCategory, products]);

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  const goToInfoClient = () => {
    history.push('./info-client');
  };

  const handleClick = () => {
    const orderItems = Object.keys(order.getProducts()).length;
    if (orderItems > 0) {
      setShoppingCart(true);
    }
  };

  const handleFilterProducts = event => {
    const {
      target: { value },
    } = event;

    if (value) {
      const lowerCaseValue = value.toLowerCase();
      const filteredProducts = products.filter(product => {
        const lowerCaseProduct = product.name.toLowerCase();
        return lowerCaseProduct.startsWith(lowerCaseValue);
      });
      setProductsList(filteredProducts);
    } else {
      filter();
    }
  };

  return (
    <Fragment>
      {!shoppingCart && !orderDetails && (
        <Fragment>
          <StickyContainer>
            <HorizontalContainer>
              <Label font="s">Nuevo pedido</Label>
              <RoundButton color="secondary" onClick={goToInfoClient}>
                Cancelar
              </RoundButton>
            </HorizontalContainer>
            <CategoriesNavBar
              setActiveCategory={setActiveCategory}
              activeCategory={activeCategory}
            />
          </StickyContainer>
          <Container color="#F3F4FB" className="product-list">
            <SearchBar
              placeholder="Buscar por nombre"
              handleChange={handleFilterProducts}
            />
            {isLoading && <Loading />}
            {productsList &&
              productsList.map(product => {
                return (
                  <ProductCard
                    key={product.id}
                    product={product}
                    addItem={order.addItemToProduct}
                    subtractItem={order.subtractItemFromProduct}
                  />
                );
              })}
          </Container>
          <BottomContainer bottom="48px">
            <div className="user-info">
              <UserIcon
                width="32px"
                height="32px"
                imgWidth="20px"
                imgHeight="17px"
              >
                <img alt="User" src={logoImage} />
              </UserIcon>
              <Label font="s" margin="0 0 0 5px">
                {client && client.name}
              </Label>
            </div>
            <div className="info">
              <Label font="sm" opacity="50%" margin="0">
                {order.getTotalProducts()}
              </Label>
              <Label font="sm" opacity="50%" margin="0">
                {new Money(order.getTotalPrice()).format()}
              </Label>
            </div>
          </BottomContainer>
          <FullSizeButton onClick={handleClick}>
            Continuar a carrito
          </FullSizeButton>
        </Fragment>
      )}
      {shoppingCart && !orderDetails && (
        <ShoppingCart
          client={client}
          getProducts={order.getProducts}
          getTotalProducts={order.getTotalProducts}
          getTotalPrice={order.getTotalPrice}
          setShoppingCart={setShoppingCart}
          addItem={order.addItemToProduct}
          subtractItem={order.subtractItemFromProduct}
          setOrderDetails={setOrderDetails}
        />
      )}
      {orderDetails && (
        <OrderDetails
          client={client}
          order={order}
          getTotalProducts={order.getTotalProducts}
          getDate={order.getDate}
          getTime={order.getTime}
          getProducts={order.getProducts}
          getOrder={order.getOrder}
          getNotes={order.getNotes}
          getTotalPrice={order.getTotalPrice}
          setDate={order.setDate}
          setTime={order.setTime}
          setNotes={order.setNotes}
          setOrderDetails={setOrderDetails}
          productsList={productsList}
        />
      )}
    </Fragment>
  );
};

export default withRouter(CreateOrder);
