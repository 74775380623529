import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Money } from '../../../../types';
import logoImage from '../../../../static/logoImage.jpeg';
import {
  Container,
  StickyContainer,
  HorizontalContainer,
  Label,
  RoundButton,
  ProductCard,
  BottomContainer,
  UserIcon,
  FullSizeButton,
} from '../../../../components';

const ShoppingCart = props => {
  const {
    history,
    getProducts,
    getTotalProducts,
    getTotalPrice,
    setShoppingCart,
    setOrderDetails,
    addItem,
    subtractItem,
    client,
  } = props;

  const products = getProducts();

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  return (
    <Fragment>
      <StickyContainer>
        <HorizontalContainer>
          <Label font="s">Carrito de compras</Label>
          <RoundButton color="secondary" onClick={() => setShoppingCart(false)}>
            Cancelar
          </RoundButton>
        </HorizontalContainer>
      </StickyContainer>
      <Container color="#F3F4FB">
        {products.map(product => {
          return (
            <ProductCard
              product={product}
              key={product.id}
              addItem={addItem}
              subtractItem={subtractItem}
            />
          );
        })}
      </Container>
      <BottomContainer bottom="48px">
        <div className="user-info">
          <UserIcon onClick={goToAdminProfile} width="32px" height="32px">
            <img alt="User" src={logoImage} height="32px" />
          </UserIcon>
          <Label font="s" margin="0 0 0 5px">
            {client.name}
          </Label>
        </div>
        <div className="info">
          <Label font="sm" opacity="50%" margin="0">
            {getTotalProducts()}
          </Label>
          <Label font="sm" opacity="50%" margin="0">
            {new Money(getTotalPrice()).format()}
          </Label>
        </div>
      </BottomContainer>
      <FullSizeButton onClick={() => setOrderDetails(true)}>
        Continuar
      </FullSizeButton>
    </Fragment>
  );
};

ShoppingCart.propTypes = {
  getProducts: PropTypes.func,
  getTotalProducts: PropTypes.func,
  getTotalPrice: PropTypes.func,
  setShoppingCart: PropTypes.func,
  setOrderDetails: PropTypes.func,
  addItem: PropTypes.func,
  subtractItem: PropTypes.func,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(ShoppingCart);
