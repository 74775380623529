import React, { Fragment, useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import TodayIcon from '@material-ui/icons/Today';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PlaceIcon from '@material-ui/icons/Place';
import NoteIcon from '@material-ui/icons/Note';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { createOrder } from '../../../../graphql/mutations';
import { Money, DateUserFormatter } from '../../../../types';
import logoImage from '../../../../static/logoImage.jpeg';
import {
  Container,
  HorizontalContainer,
  RoundButton,
  Label,
  Card,
  UserIcon,
  FullSizeButton,
  Success,
} from '../../../../components';
import {
  SelectDateModal,
  SelectTimeModal,
  NotesModal,
  ProductsModal,
  AddressModal,
} from '../../components';
import createOrderProduct from '../../graphql/createOrderProduct';

const OrderDetails = props => {
  const {
    history,
    getTotalProducts,
    getDate,
    getTime,
    getProducts,
    getOrder,
    getNotes,
    getTotalPrice,
    setDate,
    setTime,
    setNotes,
    setOrderDetails,
    client,
  } = props;
  const [showDateModal, setShowDateModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [success, setSuccess] = useState(false);

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  const isOrderComplete = React.useCallback(() => {
    return getTotalProducts() && getDate() && getTime();
  }, [getTotalProducts, getDate, getTime]);

  useEffect(() => {
    if (isOrderComplete()) {
      setShowError(false);
    }
  }, [isOrderComplete]);

  const handleSubmit = () => {
    if (isOrderComplete()) {
      createNewOrder();
    } else {
      setShowError(true);
    }
  };

  const createOrderProducts = async orderId => {
    const products = getProducts();
    return Promise.all(
      products.map(async product => {
        try {
          await API.graphql(
            graphqlOperation(createOrderProduct, {
              input: {
                orderId: orderId,
                productId: product.id,
                productItems: product.items,
              },
            })
          );
        } catch (error) {
          console.log(error);
        }
      })
    );
  };

  const createNewOrder = async () => {
    const order = getOrder();

    const orderInput = {
      input: {
        amount: getTotalPrice(),
        delivery_date: getDate(),
        delivery_hour: order.time,
        note_title: order.notes && order.notes.title,
        note_content: order.notes && order.notes.content,
        orderClientId: client.id,
        status: 'IN_PROGRESS',
      },
    };

    try {
      const { data } = await API.graphql(
        graphqlOperation(createOrder, orderInput)
      );

      if (data) {
        await createOrderProducts(data.createOrder.id);
      }
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Container color="#F3F4FB" minHeight="100vh" padding="0">
        <HorizontalContainer padding="0 17px">
          <Label font="s" margin="0">
            Detalles de pedido
          </Label>
          <RoundButton color="secondary" onClick={() => setOrderDetails(false)}>
            Regresar
          </RoundButton>
        </HorizontalContainer>
        <Card width="100%" margin="10px 0 0 0">
          <div className="card-top no-border">
            <UserIcon onClick={goToAdminProfile} width="32px" height="32px">
              <img alt="User" src={logoImage} height="32px" />
            </UserIcon>
            <Label font="s" margin="0 0 0 6px">
              {client.name}
            </Label>
          </div>
          <div className="order-row">
            <div className="row-label">
              <TodayIcon style={{ opacity: '50%' }} fontSize="small" />
              <Label font="sm" opacity="50%" margin="0 0 0 10px">
                Fecha de entrega *
              </Label>
            </div>
            <button type="button" onClick={() => setShowDateModal(true)}>
              {getDate() ? new DateUserFormatter(getDate()).format() : 'Elegir'}
            </button>
          </div>
          <div className="order-row">
            <div className="row-label">
              <AccessTimeIcon style={{ opacity: '50%' }} fontSize="small" />
              <Label font="sm" opacity="50%" margin="0 0 0 10px">
                Horario *
              </Label>
            </div>
            <button type="button" onClick={() => setShowTimeModal(true)}>
              {getTime() || 'Elegir'}
            </button>
          </div>
          <div className="order-row">
            <div className="row-label">
              <PlaceIcon style={{ opacity: '50%' }} fontSize="small" />
              <Label font="sm" opacity="50%" margin="0 0 0 10px">
                Domicilio
              </Label>
            </div>
            <button type="button" onClick={() => setShowAddressModal(true)}>
              Ver
            </button>
          </div>
          <div className="order-row">
            <div className="row-label">
              <NoteIcon style={{ opacity: '50%' }} fontSize="small" />
              <Label font="sm" opacity="50%" margin="0 0 0 10px">
                Notas
              </Label>
            </div>
            <button type="button" onClick={() => setShowNotesModal(true)}>
              {getNotes() ? getNotes().title : 'Agregar'}
            </button>
          </div>
          <div className="order-row">
            <div className="row-label">
              <ShoppingCartIcon style={{ opacity: '50%' }} fontSize="small" />
              <Label font="sm" opacity="50%" margin="0 0 0 10px">
                Productos
              </Label>
            </div>
            <button type="button" onClick={() => setShowProductsModal(true)}>
              Ver {getTotalProducts()} Productos
            </button>
          </div>
          <div className="order-row">
            <div className="row-label">
              <MonetizationOnIcon style={{ opacity: '50%' }} fontSize="small" />
              <Label font="sm" opacity="50%" margin="0 0 0 10px">
                Total a cobrar
              </Label>
            </div>
            <Label font="s" margin="0">
              {new Money(getTotalPrice()).format()}
            </Label>
          </div>
        </Card>
        <Label font="xs" opacity="50%" margin="10px 17px" justify="left">
          Campos obligatorios *
        </Label>
        {showError && (
          <Label font="xs" color="red" justify="left" margin="0 0 0 17px">
            <ErrorOutlineIcon fontSize="small" style={{ marginRight: '5px' }} />
            Complete la información para continuar
          </Label>
        )}
      </Container>
      <FullSizeButton onClick={handleSubmit}>
        Finalizar y crear pedido
      </FullSizeButton>
      {success && (
        <Success
          text="¡Genial, la orden fue creada y guardada!"
          buttonText="Ir a ordenes"
          link="./orders"
          imageWidth="146px"
        />
      )}
      {showDateModal && (
        <SelectDateModal
          setShowDateModal={setShowDateModal}
          setDate={setDate}
        />
      )}
      {showTimeModal && (
        <SelectTimeModal
          setShowTimeModal={setShowTimeModal}
          setTime={setTime}
        />
      )}
      {showNotesModal && (
        <NotesModal setShowNotesModal={setShowNotesModal} setNotes={setNotes} />
      )}
      {showProductsModal && (
        <ProductsModal
          setShowProductsModal={setShowProductsModal}
          products={getProducts()}
          numberOfProducts={getTotalProducts()}
        />
      )}
      {showAddressModal && (
        <AddressModal
          setShowAddressModal={setShowAddressModal}
          client={client}
        />
      )}
    </Fragment>
  );
};

OrderDetails.propTypes = {
  getTotalProducts: PropTypes.func,
  getDate: PropTypes.func,
  getTime: PropTypes.func,
  getProducts: PropTypes.func,
  getOrder: PropTypes.func,
  getNotes: PropTypes.func,
  getTotalPrice: PropTypes.func,
  setDate: PropTypes.func,
  setTime: PropTypes.func,
  setNotes: PropTypes.func,
  setOrderDetails: PropTypes.func,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(OrderDetails);
