const STATUS = {
  IN_PROGRESS: 'En proceso',
  DELIVERED: 'Entregada',
  DELIVERED_AND_PAID: 'Pagada',
  CANCELED: 'Cancelada',
};

function StatusFormatter(statusString) {
  this.statusString = statusString;

  const format = () => {
    return STATUS[statusString];
  };

  return { format };
}

export default StatusFormatter;
