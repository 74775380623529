import styled from 'styled-components';

const Wrapper = styled.div`
  .date-picker {
    input {
      height: 56px;
      width: 100%;
      border: 1px solid #eaeaea;
      border-radius: 3px;
      margin-bottom: 10px;
      padding: 0 17px;
      outline: none;
      background-color: white;
    }

    input:focus {
      border: 1px solid #1a73e8;
    }
  }
`;

export default Wrapper;
