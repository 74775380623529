import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Dropdown, Modal } from '../../../../components';
import {
  errorMessages,
  dayOptions,
  monthOptions,
  yearOptions,
} from '../../../../utils';

const SelectDateModal = props => {
  const { setShowDateModal, setDate } = props;
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeDay, setActiveDay] = useState({});
  const [activeMonth, setActiveMonth] = useState({});
  const [activeYear, setActiveYear] = useState({});

  const handleDayChange = day => setActiveDay(day);

  const handleMonthChange = month => setActiveMonth(month);

  const handleYearChange = year => setActiveYear(year);

  const handleCloseModal = () => {
    setShowDateModal(false);
  };

  const isValidDate = (year, month, day) => {
    const date = new Date(year, month, day);
    const today = new Date();

    if (date.getTime() < today.getTime()) {
      return false;
    }

    return true;
  };

  const formatDate = (year, month, day) => {
    let formattedYear = year;
    let formattedMonth = month;
    let formattedDay = day;

    if (month < 10) {
      formattedMonth = `0${month}`;
    }

    if (day < 10) {
      formattedDay = `0${day}`;
    }

    const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

    return formattedDate;
  };

  const handleSaveDate = () => {
    const completeData = activeDay.text && activeMonth.text && activeYear.text;

    if (completeData) {
      const day = activeDay.value;
      const month = activeMonth.value;
      const year = activeYear.value;
      const isAnExistentDate =
        moment([year, month, day]).format() === 'Invalid date' ? false : true;

      if (!isAnExistentDate) {
        setErrorMessage(errorMessages.notPossibleDate);
        setShowErrorMessage(true);
      } else {
        const formattedDate = formatDate(year, month + 1, day);
        setDate(formattedDate);
        setShowDateModal(false);
      }
    } else {
      setErrorMessage(errorMessages.incompleteData);
      setShowErrorMessage(true);
    }
  };

  return (
    <Modal
      title="Día/Mes/Año"
      subTitle="Fecha"
      handleClose={handleCloseModal}
      handleSave={handleSaveDate}
      showError={showErrorMessage}
      errorMessage={errorMessage}
      saveButton={true}
    >
      <Dropdown
        margin="0 0 10px 0"
        options={dayOptions}
        placeholder="Día"
        activeOption={activeDay}
        handleChange={handleDayChange}
      />
      <Dropdown
        margin="0 0 10px 0"
        options={monthOptions}
        placeholder="Mes"
        activeOption={activeMonth}
        handleChange={handleMonthChange}
      />
      <Dropdown
        margin="0 0 10px 0"
        options={yearOptions}
        placeholder="Año"
        activeOption={activeYear}
        handleChange={handleYearChange}
      />
    </Modal>
  );
};

SelectDateModal.propTypes = {
  setShowDateModal: PropTypes.func,
  setDate: PropTypes.func,
};

export default SelectDateModal;
