import React, { Fragment, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { Money, DateUserFormatter, StatusFormatter } from '../../types';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import listOrders from './graphql/listOrders';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import logoImage from '../../static/logoImage.jpeg';
import {
  Container,
  StickyContainer,
  HorizontalContainer,
  Label,
  RoundButton,
  UserIcon,
  NavBar,
  Card,
  NoDataMessage,
  Loading,
  Menu,
  StatusTag,
} from '../../components';

const Orders = props => {
  const { history } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [orderByCity, setOrdersByCity] = useState([]);
  const [status, setStatus] = useState('idle');
  const isLoading = status === 'pending';
  const city = {
    id: window.localStorage.getItem('cityId'),
    name: window.localStorage.getItem('cityName'),
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setStatus('pending');
        const { data } = await API.graphql(
          graphqlOperation(listOrders, { limit: 999 })
        );

        if (data) {
          const orders = data.listOrders.items;
          const filteredOrders = orders.filter(
            order => order.client.city.id === city.id
          );
          setOrdersByCity(filteredOrders);
        }
        setStatus('resolved');
      } catch (error) {
        setStatus('rejected');
      }
    };

    fetchOrders();
  }, [city.id]);

  const goToCities = () => {
    history.push('./cities');
  };

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  const openMenu = () => {
    setShowMenu(prevState => !prevState);
  };

  const goToOrderDetails = orderId => {
    window.localStorage.setItem('orderId', orderId);
    history.push('./order-details');
  };

  return (
    <Fragment>
      {showMenu && <Menu setShowMenu={setShowMenu} />}
      <StickyContainer>
        <HorizontalContainer>
          <RoundButton color="secondary" onClick={openMenu}>
            Menú
          </RoundButton>
          <UserIcon
            width="32px"
            height="32px"
            imgWidth="20px"
            imgHeight="17px"
            onClick={goToAdminProfile}
          >
            <img alt="User" src={logoImage} />
          </UserIcon>
        </HorizontalContainer>
        <HorizontalContainer>
          <Label font="md" margin="0">
            {city.name === null ? (
              <RoundButton onClick={goToCities} color="primary">
                <ArrowBack fontSize="small" style={{ marginRight: '5px' }} />
                Regresar a ciudades
              </RoundButton>
            ) : (
                city.name
              )}
          </Label>
        </HorizontalContainer>
      </StickyContainer>
      <Container color="#F3F4FB" padding="17px 17px 100px 17px">
        {city.name === null && (
          <NoDataMessage>
            <div className="icon-container">
              <MotorcycleIcon style={{ opacity: '50%' }} />
            </div>
            <Label font="md" opacity="50%" textAlign="center">
              No se ha seleccionado una ciudad
            </Label>
          </NoDataMessage>
        )}
        {isLoading && <Loading />}
        {orderByCity.map(order => {
          return (
            <Card
              key={order.id}
              width="100%"
              height="126px"
              padding="0"
              margin=" 0 0 10px 0"
              onClick={() => goToOrderDetails(order.id)}
            >
              <div className="card-top no-border">
                <UserIcon
                  width="32px"
                  height="32px"
                  imgWidth="20px"
                  imgHeight="17px"
                >
                  <img alt="User" src={logoImage} />
                </UserIcon>
                <Label font="s" margin="0 0 0 6px">
                  {order.client.name}
                </Label>
                <div className="icon-card">
                  <ArrowDropDownIcon style={{ opacity: '50%' }} />
                </div>
              </div>
              <div className="card-bottom">
                <Label font="sm" margin="0">
                  {new DateUserFormatter(order.delivery_date).format()}
                </Label>
                <Label font="xxs" opacity="50%" margin="0">
                  {new Money(order.amount).format()}
                </Label>
                <StatusTag status={order.status}>
                  {new StatusFormatter(order.status).format()}
                </StatusTag>
              </div>
            </Card>
          );
        })}
        {orderByCity.length === 0 && !isLoading && (
          <NoDataMessage>
            <div className="icon-container">
              <MotorcycleIcon style={{ opacity: '50%' }} />
            </div>
            <Label font="md" opacity="50%" textAlign="center">
              No se han creado pedidos
            </Label>
          </NoDataMessage>
        )}
        <NavBar />
      </Container>
    </Fragment>
  );
};

export default withRouter(Orders);
