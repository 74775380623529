import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const StyledIntro = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.font.xxl};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 0.8s infinite beat;

  @keyframes beat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const Intro = props => {
  const { history } = props;

  const goToLogin = React.useCallback(() => {
    history.push('./login');
  }, [history]);

  useEffect(() => {
    window.setTimeout(goToLogin, 4000);
  }, [goToLogin]);

  return <StyledIntro>beit.co</StyledIntro>;
};

export default withRouter(Intro);
