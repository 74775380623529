import { useState } from 'react';

const useOrder = () => {
  const [order, setOrder] = useState({ products: {} });

  const getTotalPriceByProduct = product => {
    return Number(product.price * product.items);
  };

  const removeProduct = productId => {
    const orderCopy = { ...order };
    delete orderCopy.products[productId];

    setOrder(orderCopy);
  };

  const addItemToProduct = product => {
    const { id, items } = product;

    setOrder(prevOrder => {
      return {
        ...prevOrder,
        products: {
          ...prevOrder.products,
          [id]: { ...product, items: items + 1 },
        },
      };
    });
  };

  const subtractItemFromProduct = product => {
    const { id, items } = product;
    const hasNoItems = items - 1 <= 0;

    if (hasNoItems) {
      removeProduct(id);
    } else {
      setOrder(prevOrder => {
        return {
          ...prevOrder,
          products: {
            ...prevOrder.products,
            [id]: { ...product, items: items - 1 },
          },
        };
      });
    }
  };

  const getOrder = () => {
    return order;
  };

  const getTotalProducts = () => {
    return Object.keys(order.products).length;
  };

  const getProducts = () => {
    return Object.values(order.products);
  };

  const getTotalPrice = () => {
    const reducer = (total, product) =>
      Number(total) + getTotalPriceByProduct(product);
    const initialValue = 0;

    return Object.values(order.products).reduce(reducer, initialValue);
  };

  const setDate = date => {
    setOrder({ ...order, date: date });
  };

  const getDate = () => {
    return order.date;
  };

  const setTime = time => {
    setOrder({ ...order, time: time });
  };

  const getTime = () => {
    return order.time;
  };

  const setNotes = notes => {
    setOrder({ ...order, notes: notes });
  };

  const getNotes = () => {
    return order.notes;
  };

  return {
    addItemToProduct,
    subtractItemFromProduct,
    getOrder,
    getTotalProducts,
    getProducts,
    getTotalPrice,
    setDate,
    getDate,
    setTime,
    getTime,
    setNotes,
    getNotes,
  };
};

export default useOrder;
