import styled from 'styled-components';

const CardMenu = styled.div`
  height: 492px;
  width: 343px;
  background-color: white;
  border-radius: 0 10px 10px 0;
  box-shadow: 0px 0.5px 3px 0px #0000001a;

  .menu-title,
  .menu-route {
    height: 63px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 17px;
    border-bottom: 1px solid #F3F4FB;
  }

  .menu-route {
    border: none;
    outline none;
    background-color: white;
    padding: 0 17px;
    border-bottom: 1px solid #F3F4FB;

    h3 {
      margin: 0 0 0 10px;
      opacity: 50%;
      font-size: ${props => props.theme.font.sm};
    }

    svg {
      opacity: 50%;
    }

    &:hover {
      background-color: #F3F8FE;

      h3, svg {
        color: ${props => props.theme.colors.primary};
        opacity: 1;
      }
    }
  }

  .active {
    background-color: #F3F8FE;

    h3, svg {
      color: ${props => props.theme.colors.primary};
      opacity: 1;
    }
  }
`;

export default CardMenu;
