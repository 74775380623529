import styled from 'styled-components';

const Card = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: white;
  padding: ${props => props.padding};
  border-radius: 10px;
  box-shadow: 0px 0.5px 3px 0px #0000001a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${props => props.margin};
  cursor: pointer;

  a {
    text-decoration: none;
    color: #000;
  }

  &:first-child {
    margin-top: 0;
  }

  .card-info {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: flex-start;
    opacity: 50%;
  }

  .card-order {
    height: 63px;
    width: 100%;
    padding: 0 18px;
    display: grid;
    grid-template-columns: 50% 20% 30%;
    align-content: center;
    border-top: 1px solid #f3f4fb;
  }

  .card-top,
  .card-bottom {
    height: 63px;
    display: flex;
    align-items: center;
    padding: 0 18px;
  }

  .card-top {
    .icon-card {
      position: absolute;
      right: 36px;
    }

    border-bottom: 1px solid #f3f4fb;

    .search-bar {
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
    }

    &:last-child {
      border: none;
    }
  }

  .no-border {
    border: none;
  }

  .order-row {
    height: 63px;
    padding: 0 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .row-label {
      display: flex;
      align-items: center;
    }

    button {
      border: none;
      background-color: white;
      outline: none;
      font-size: 16px;
    }
  }

  .card-bottom {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    border-top: 1px solid #f3f4fb;
  }
`;

export default Card;
