import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Input, TextArea } from '../../../../components';

const NotesModal = props => {
  const { setShowNotesModal, notes } = props;

  const { title, content } = notes;

  const handleCloseModal = () => {
    setShowNotesModal(false);
  };

  return (
    <Modal
      title={title}
      subTitle="Notas"
      handleClose={handleCloseModal}
      saveButton={false}
    >
      <Input placeholder="Título" margin="0 0 10px 0" value={title} disabled />
      <TextArea placeholder="Nota" value={content} disabled />
    </Modal>
  );
};

NotesModal.propTypes = {
  setShowNotesModal: PropTypes.func,
  setNotes: PropTypes.object,
};

export default NotesModal;
