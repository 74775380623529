const listOrders = `
  query ListOrders {
    listOrders {
      items {
        id
        delivery_date
        status
        amount
        client {
          id
          name
          city {
            id
            name
          }
        }
      }
    }
  }
`;

export default listOrders;
