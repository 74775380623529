import styled from 'styled-components';

const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props =>
    props.justify ? props.justify : 'space-between'};
  align-items: center;
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  height: 56px;
`;

export default HorizontalContainer;
