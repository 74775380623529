/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      state
      country
    }
  }
`;
export const listCitys = /* GraphQL */ `
  query ListCitys(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        state
        country
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      phone_number
      address
      suburb
      zipCode
      reference
      name
      affiliation_date
      orders {
        items {
          id
          creation_date
          amount
          status
          delivery_date
          delivery_hour
          note_title
          note_content
        }
        nextToken
      }
      city {
        id
        name
        state
        country
      }
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phone_number
        address
        suburb
        zipCode
        reference
        name
        affiliation_date
        orders {
          nextToken
        }
        city {
          id
          name
          state
          country
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      creation_date
      amount
      status
      delivery_date
      delivery_hour
      note_title
      note_content
      client {
        id
        phone_number
        address
        suburb
        zipCode
        reference
        name
        affiliation_date
        orders {
          nextToken
        }
        city {
          id
          name
          state
          country
        }
      }
      products {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creation_date
        amount
        status
        delivery_date
        delivery_hour
        note_title
        note_content
        client {
          id
          phone_number
          address
          suburb
          zipCode
          reference
          name
          affiliation_date
        }
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      id
      orderId
      productId
      productItems
      order {
        id
        creation_date
        amount
        status
        delivery_date
        delivery_hour
        note_title
        note_content
        client {
          id
          phone_number
          address
          suburb
          zipCode
          reference
          name
          affiliation_date
        }
        products {
          nextToken
        }
      }
      product {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        productId
        productItems
        order {
          id
          creation_date
          amount
          status
          delivery_date
          delivery_hour
          note_title
          note_content
        }
        product {
          id
          name
          price
          quantity
          items
        }
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      price
      quantity
      items
      category {
        id
        type
        products {
          nextToken
        }
      }
      orders {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
      categories {
        items {
          id
          categoryId
          productId
        }
        nextToken
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCategoryProduct = /* GraphQL */ `
  query GetCategoryProduct($id: ID!) {
    getCategoryProduct(id: $id) {
      id
      categoryId
      productId
      category {
        id
        type
        products {
          nextToken
        }
      }
      product {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const listCategoryProducts = /* GraphQL */ `
  query ListCategoryProducts(
    $filter: ModelCategoryProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        productId
        category {
          id
          type
        }
        product {
          id
          name
          price
          quantity
          items
        }
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      type
      products {
        items {
          id
          categoryId
          productId
        }
        nextToken
      }
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        products {
          nextToken
        }
      }
      nextToken
    }
  }
`;
