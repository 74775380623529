import React, { useState, Fragment } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import createCity from './graphql/createCity';
import { withRouter } from 'react-router-dom';

import {
  FullSizeButton,
  Container,
  HorizontalContainer,
  Label,
  RoundButton,
  Success,
  Input,
} from '../../components';

const CreateCity = props => {
  const { history } = props;
  const [newCity, setNewCity] = useState('');
  const [success, setSuccess] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const successText = '¡Genial, has creado una nueva ciudad!';
  const buttonSuccessText = 'Ir a ciudades';

  const submitForm = async event => {
    event.preventDefault();

    if (newCity) {
      try {
        const { data } = await API.graphql(
          graphqlOperation(createCity, { input: { name: newCity } })
        );

        if (data) {
          setSuccess(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChange = event => {
    const { target = {} } = event;
    const { value } = target;
    setNewCity(value.replace(/[0-9]/, ''));

    if (newCity) {
      setActiveButton(true);
    }
  };

  const goToCities = () => {
    history.push('./cities');
  };

  return (
    <Fragment>
      {!success && (
        <Container color="#F3F4FB" minHeight="100vh">
          <HorizontalContainer>
            <Label font="s">Crear nueva ciudad</Label>
            <RoundButton color="secondary" onClick={goToCities}>
              Cancelar
            </RoundButton>
          </HorizontalContainer>
          <form onSubmit={submitForm}>
            <Input
              placeholder="Nombre de la ciudad"
              onChange={handleChange}
              value={newCity}
            />
            <FullSizeButton disabled={!activeButton}>
              Crear nueva ciudad
            </FullSizeButton>
          </form>
        </Container>
      )}
      {success && (
        <Success
          text={successText}
          link="./cities"
          buttonText={buttonSuccessText}
          imageWidth="138px"
        />
      )}
    </Fragment>
  );
};

export default withRouter(CreateCity);
