import styled from 'styled-components';

const CircleButton = styled.button`
  border: none;
  outline: none;
  border-radius: 100%;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${props =>
    (props.color === 'primary' && 'white') ||
    (props.color === 'secondary' && 'black')};
  background-color: ${props =>
    (props.color === 'primary' && props.theme.colors.primary) ||
    (props.color === 'secondary' && 'white')};
  box-shadow: ${props =>
    (props.color === 'primary' && 'none') ||
    (props.color === 'secondary' && '0px 0.5px 3px 0px #0000001A')};
`;

export default CircleButton;
