import styled from 'styled-components';

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding: ${props => (props.padding ? props.padding : '17px')};
  padding-bottom: 0;
  background-color: ${props => (props.color ? props.color : '#f3f4fb')};
  z-index: 5;
`;

export default StickyContainer;
