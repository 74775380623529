import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';


import logoImage from '../../static/logoImage.jpeg';
import {
  Container,
  HorizontalContainer,
  Label,
  RoundButton,
  UserIcon,
  NavBar,
  Menu,
} from '../../components';

const Overview = props => {
  const { history } = props;
  const [showMenu, setShowMenu] = useState(false);
  const city = {
    id: window.localStorage.getItem('cityId'),
    name: window.localStorage.getItem('cityName'),
  };

  const openMenu = () => {
    setShowMenu(prevState => !prevState);
  };

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  return (
    <>
      {showMenu && <Menu setShowMenu={setShowMenu} />}
      <Container color="#F3F4FB" minHeight="100vh">
        <HorizontalContainer>
          <RoundButton color="secondary" onClick={openMenu}>
            Menú
          </RoundButton>
          <UserIcon
            width="32px"
            height="32px"
            imgWidth="20px"
            imgHeight="17px"
            onClick={goToAdminProfile}
          >
            <img alt="User" src={logoImage} />
          </UserIcon>
        </HorizontalContainer>
        <HorizontalContainer>
          <Label font="md" margin="0">
            {city.name}
          </Label>
        </HorizontalContainer>
        <NavBar />
      </Container>
    </>
  );
};

export default withRouter(Overview);
