import styled from 'styled-components';

const SquareButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 4px;
  height: 28px;
  padding: 0 13px;
  margin: ${props => props.margin};
  color: #1A73E8 ; 
  background-color: ${props =>
    (props.color === 'primary' && props.theme.colors.primary) ||
    (props.color === 'secondary' && 'white')};
  box-shadow: ${props =>
    (props.color === 'primary' && 'none') ||
    (props.color === 'secondary' && '0px 0.5px 3px 0px #0000001A')};
`;

export default SquareButton;
