import styled from 'styled-components';

const CardsContainer = styled.div`
  .no-data-message {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export default CardsContainer;
