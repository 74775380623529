import styled from 'styled-components';

const Input = styled.input`
  height: 56px;
  width: 100%;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.lightGray};
  outline: none;
  color: black;
  font-size: ${props => props.theme.font.sm};
  margin: ${props => props.margin};

  &:focus {
    border: 1px solid ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: white;
  }
`;

export default Input;
