import media from './media';

const theme = {
  media,
  font: {
    xxs: '12px',
    xs: '13px',
    sm: '14px',
    s: '16px',
    md: '18px',
    n: '20px',
    lg: '25px',
    xl: '30px',
    xxl: '50px'
  },
  colors: {
    primary: '#1A73E8',
    gray: '#00000040',
    lightGray: '#EAEAEA'
  },
  padding: {
    mobile: '17px'
  }
};

export default theme;
