import styled from 'styled-components';

const BottomContainer = styled.div`
  height: 63px;
  width: 100%;
  position: fixed;
  bottom: ${props => props.bottom};
  left: 0;
  background-color: white;
  box-shadow: 0px 0.5px 3px 0px #0000001a;
  padding: 0 17px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};

  .user-info {
    display: flex;
    align-items: center;

    h1 {
      white-space: nowrap;
    }
  }

  .info {
    width: 130px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    margin-left: 30px;
  }
`;

export default BottomContainer;
