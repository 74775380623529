const listClients = `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        phone_number
        address
        suburb
        zipCode
        reference
        name
        affiliation_date
        orders {
          nextToken
        }
        city {
          id
          name
          state
          country
        }
      }
      nextToken
    }
  }
`;

export default listClients;
