import styled from 'styled-components';

const SuccessContainer = styled.div`
  padding: ${props =>
    props.padding ? props.padding : props.theme.padding.mobile};
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${props => props.color};
`;

export default SuccessContainer;
