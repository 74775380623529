import styled from 'styled-components';

const MenuContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9;
  overflow: none;
  background-color: #f3f4fb;
`;

export default MenuContainer;
