import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import { Modal, Dropdown } from '../../../../components';
import { updateOrder } from '../../../../graphql/mutations';
import { getOrder } from '../../../../graphql/queries';
import { StatusFormatter } from '../../../../types';

const StatusModal = props => {
  const { setShowStatusModal, orderId, setStatus } = props;
  const [activeStatus, setActiveStatus] = useState({});

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const { data } = await API.graphql(
          graphqlOperation(getOrder, { id: orderId })
        );

        if (data) {
          setActiveStatus({
            text: new StatusFormatter(data.getOrder.status).format(),
            value: data.getOrder.status,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchStatus();
  }, [orderId, setStatus]);

  const statusOptions = [
    { text: 'En proceso', value: 'IN_PROGRESS' },
    { text: 'Entregada', value: 'DELIVERED' },
    { text: 'Pagada', value: 'DELIVERED_AND_PAID' },
    { text: 'Cancelada', value: 'CANCELED' },
  ];

  const handleStatusChange = status => setActiveStatus(status);

  const handleCloseModal = () => {
    setShowStatusModal(false);
  };

  const handleSaveModal = async () => {
    const orderStatusInput = {
      input: {
        id: orderId,
        status: activeStatus.value,
      },
    };

    try {
      const { data } = await API.graphql(
        graphqlOperation(updateOrder, orderStatusInput)
      );

      if (data) {
        setStatus(data.updateOrder.status);
        setShowStatusModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={activeStatus.text}
      subTitle="Status"
      handleClose={handleCloseModal}
      handleSave={handleSaveModal}
      saveButton={true}
    >
      <Dropdown
        placeholder={activeStatus.text}
        activeOption={activeStatus}
        numberOfOptions="4"
        options={statusOptions}
        handleChange={handleStatusChange}
        margin="0 0 100px 0"
      />
    </Modal>
  );
};

export default StatusModal;
