import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import { Container, Label, Input, Button } from '../../components';
import { InputContainer } from './components';

const Login = props => {
  const { history } = props;
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [inputType, setInputType] = useState('password');
  const [errorMessage, setErrorMessage] = useState('');

  const redirect = userType => {
    if (userType === 'Admin') {
      history.push('/cities');
    } else if (userType === 'DeliveryMan') {
      history.push('/orders-distributor');
    } else {
      history.push('/');
    }
  };

  const submitForm = async e => {
    e.preventDefault();

    if (userName && password) {
      try {
        const user = await Auth.signIn(userName, password);

        if (user) {
          const userType =
            user.signInUserSession.accessToken.payload['cognito:groups'][0];

          redirect(userType);
        }
      } catch (error) {
        setErrorMessage('Nombre de usuario o contraseña incorrectos');
      }
    } else {
      setErrorMessage('Complete los datos para continuar');
    }
  };

  const handleChange = event => {
    const { target = {} } = event;
    const { value } = target;
    setUserName(value);
  };

  const handleChangePassword = event => {
    const { target = {} } = event;
    const { value } = target;
    setPassword(value);
  };

  const showPassword = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <Container minHeight="100vh">
      <Label font="xl" align="center" margin="70px 0 40px 0">
        Iniciar sesión
      </Label>
      <form onSubmit={submitForm}>
        <Input
          type="text"
          placeholder="Nombre de usuario"
          onChange={handleChange}
          value={userName}
          margin="0 0 10px 0"
        />
        <InputContainer>
          <Input
            id="password-input"
            type={inputType}
            placeholder="Contraseña"
            onChange={handleChangePassword}
            value={password}
            margin="0"
          />
          <button type="button" onClick={showPassword}>
            <RemoveRedEyeIcon className="icon" style={{ opacity: '50%' }} />
          </button>
        </InputContainer>
        {errorMessage && (
          <Label font="xs" margin="10px 0" justify="flex-start" color="red">
            {errorMessage}
          </Label>
        )}
        <Button color="#1A73E8" fontColor="white">
          Iniciar sesión
        </Button>
      </form>
    </Container>
  );
};

export default withRouter(Login);
