import React, { Fragment, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import createClient from './graphql/createClient';
import { withRouter } from 'react-router-dom';

import {
  Container,
  HorizontalContainer,
  Label,
  RoundButton,
  FullSizeButton,
  Success,
  Input,
} from '../../components';

const CreateClient = props => {
  const { history } = props;
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const city = {
    id: window.localStorage.getItem('cityId'),
    name: window.localStorage.getItem('cityName'),
  };
  const [newClient, setNewClient] = useState({
    name: '',
    address: '',
    zipCode: '',
    suburb: '',
    reference: '',
    phone_number: '',
  });
  const successText = '¡Genial, has creado un nuevo cliente!';
  const buttonSuccessText = 'Ir a clientes';

  const goToClients = () => {
    history.push('/clients');
  };

  const isCompleteData = () => {
    const { name, address, zipCode, suburb, phone_number } = newClient;

    if (name && address && suburb && zipCode.length === 5) {
      if (phone_number.length === 10) {
        return 'completeData';
      } else return 'incompletePhoneNumber';
    }

    return 'incompleteData';
  };

  const submitForm = async event => {
    event.preventDefault();

    const inputObject = {
      input: {
        name: newClient.name,
        address: newClient.address,
        zipCode: newClient.zipCode,
        suburb: newClient.suburb,
        reference: newClient.reference ? newClient.reference : null,
        affiliation_date: new Date().toISOString().slice(0, 10),
        phone_number: newClient.phone_number,
        clientCityId: city.id,
      },
    };

    if (isCompleteData() === 'completeData') {
      try {
        const { data } = await API.graphql(
          graphqlOperation(createClient, inputObject)
        );

        if (data) {
          setSuccess(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (isCompleteData() === 'incompletePhoneNumber') {
      setErrorMessage('El número telefónico debe contener 10 dígitos');
    } else {
      setErrorMessage('Complete los datos para continuar');
    }
  };

  const handleChangeString = event => {
    const { target = {} } = event;
    const { name, value } = target;
    setNewClient({ ...newClient, [name]: value.replace(/[0-9]/, '') });
  };

  const handleChange = event => {
    const { target = {} } = event;
    const { name, value } = target;
    setNewClient({ ...newClient, [name]: value });
  };

  const handleChangeNumber = event => {
    const { target = {} } = event;
    const { name, value } = target;
    setNewClient({ ...newClient, [name]: value.replace(/[^0-9.]/g, '') });
  };

  return (
    <Fragment>
      {!success && (
        <Container color="#F3F4FB" minHeight="100vh">
          <HorizontalContainer>
            <Label font="s" margin="0">
              Crear nuevo cliente
            </Label>
            <RoundButton color="secondary" onClick={goToClients}>
              Cancelar
            </RoundButton>
          </HorizontalContainer>
          <form onSubmit={submitForm}>
            <Input
              type="text"
              name="name"
              margin="0 0 10px 0"
              placeholder="Nombre completo *"
              value={newClient.name}
              onChange={handleChangeString}
            />
            <Input
              type="text"
              name="address"
              margin="0 0 10px 0"
              placeholder="Domicilio *"
              value={newClient.address}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="suburb"
              margin="0 0 10px 0"
              placeholder="Colonia *"
              value={newClient.suburb}
              onChange={handleChangeString}
            />
            <Input
              type="text"
              name="reference"
              margin="0 0 10px 0"
              placeholder="Referencia"
              value={newClient.reference}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="zipCode"
              margin="0 0 10px 0"
              placeholder="Código postal *"
              maxLength="5"
              value={newClient.zipCode}
              onChange={handleChangeNumber}
            />
            <Input
              type="text"
              name="phone_number"
              margin="0 0 10px 0"
              placeholder="Número de teléfono *"
              maxLength="10"
              value={newClient.phone_number}
              onChange={handleChangeNumber}
            />
            <Label font="xs" justify="flex-start" opacity="50%" margin="10px 0">
              Campos obligatorios *
            </Label>
            <Label font="xs" justify="flex-start" color="red" margin="10px 0">
              {errorMessage}
            </Label>
            <FullSizeButton
              color="primary"
              type="submit"
              disabled={!isCompleteData()}
            >
              Crear nuevo cliente
            </FullSizeButton>
          </form>
        </Container>
      )}
      {success && (
        <Success
          text={successText}
          link="./clients"
          buttonText={buttonSuccessText}
          imageWidth="124px"
        />
      )}
    </Fragment>
  );
};

export default withRouter(CreateClient);
