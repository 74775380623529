import React, { Fragment, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import {
  Container,
  HorizontalContainer,
  StickyContainer,
  UserIcon,
  Label,
  Card,
  NoDataMessage,
  Loading,
  StatusTag,
} from '../../components';
import logoImage from '../../static/logoImage.jpeg';
import { Money, DateUserFormatter, StatusFormatter } from '../../types';
import listOrders from './graphql/listOrders';

const OrdersDistributor = props => {
  const { history, userLocation } = props;
  const [ordersByCity, setOrdersByCity] = useState([]);
  const [status, setStatus] = useState('idle');
  const isLoading = status === 'pending';

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setStatus('pending');
        const { data } = await API.graphql(
          graphqlOperation(listOrders, { limit: 999 })
        );

        if (data) {
          const orders = data.listOrders.items;
          const filteredOrders = orders.filter(
            order => order.client.city.name === userLocation
          );
          setOrdersByCity(filteredOrders);
        }
        setStatus('resolved');
      } catch (error) {
        setStatus('rejected');
      }
    };

    fetchOrders();
  }, [userLocation]);

  const goToDistributorProfile = () => {
    history.push('./distributor-profile');
  };

  const goToOrderDetailsDistributor = orderId => {
    window.localStorage.setItem('orderId', orderId);
    history.push('./order-details-distributor');
  };

  return (
    <Fragment>
      <StickyContainer>
        <HorizontalContainer justify="flex-end">
          <UserIcon
            className="no-border"
            width="32px"
            height="32px"
            imgWidth="30px"
            imgHeight="30px"
            onClick={goToDistributorProfile}
          >
            <img alt="User" src={logoImage} />
          </UserIcon>
        </HorizontalContainer>
        <HorizontalContainer>
          <Label font="md" margin="0">
            {userLocation}
          </Label>
          <Label font="sm" margin="0" opacity="50%">
            Todas
            <ArrowDropDownIcon />
          </Label>
        </HorizontalContainer>
      </StickyContainer>
      <Container minHeight="100vh">
        {isLoading && <Loading />}
        {ordersByCity &&
          ordersByCity.map(order => {
            return (
              <Card
                key={order.id}
                width="100%"
                height="126px"
                padding="0"
                margin="0 0 10px 0"
                onClick={() => goToOrderDetailsDistributor(order.id)}
              >
                <div className="card-top no-border">
                  <UserIcon
                    width="32px"
                    height="32px"
                    imgWidth="20px"
                    imgHeight="17px"
                  >
                    <img alt="User" src={logoImage} height="32px" />
                  </UserIcon>
                  <Label font="s" margin="0 0 0 6px">
                    {order.client.name}
                  </Label>
                  <div className="icon-card">
                    <ArrowDropDownIcon style={{ opacity: '50%' }} />
                  </div>
                </div>
                <div className="card-bottom">
                  <Label font="sm" margin="0" justify="flex-start">
                    {new DateUserFormatter(order.delivery_date).format()}
                  </Label>
                  <Label font="xxs" opacity="50%" margin="0">
                    {new Money(order.amount).format()}
                  </Label>
                  <StatusTag status={order.status}>
                    {new StatusFormatter(order.status).format()}
                  </StatusTag>
                </div>
              </Card>
            );
          })}
        {ordersByCity.length === 0 && !isLoading && (
          <NoDataMessage>
            <div className="icon-container">
              <MotorcycleIcon style={{ opacity: '50%' }} />
            </div>
            <Label font="md" opacity="50%" textAlign="center">
              No se han creado pedidos
            </Label>
          </NoDataMessage>
        )}
      </Container>
    </Fragment>
  );
};

OrdersDistributor.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(OrdersDistributor);
