const listOrderProducts = `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        productId
        productItems
        order {
          id
          creation_date
          amount
          status
          delivery_date
          delivery_hour
          note_title
          note_content
        }
        product {
          id
          name
          price
          quantity
          items
        }
      }
      nextToken
    }
  }
`;

export default listOrderProducts;
