import React from 'react';
import { withRouter } from 'react-router-dom';
import AssessmentIcon from '@material-ui/icons/Assessment';
import WavesIcon from '@material-ui/icons/Waves';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';

import { Label } from '../../components';
import { NavBarContainer } from './components';

const NavBar = props => {
  const { history, location } = props;

  const goToOverview = () => {
    history.push('./overview');
  };

  const goToClients = () => {
    history.push('./clients');
  };

  const goToOrders = () => {
    history.push('./orders');
  };

  return (
    <NavBarContainer>
      {location.pathname === '/overview' ? (
        <button onClick={goToOverview}>
          <AssessmentIcon style={{ color: '#1A73E8' }} />
          <Label font="xs" margin="0" color="#1A73E8">
            Overview
          </Label>
        </button>
      ) : (
        <button onClick={goToOverview}>
          <AssessmentIcon style={{ opacity: '50%' }} />
          <Label font="xs" opacity="50%" margin="0">
            Overview
          </Label>
        </button>
      )}
      {location.pathname === '/clients' ? (
        <button onClick={goToClients}>
          <WavesIcon style={{ color: '#1A73E8' }} />
          <Label font="xs" margin="0" color="#1A73E8">
            Clientes
          </Label>
        </button>
      ) : (
        <button onClick={goToClients}>
          <WavesIcon style={{ opacity: '50%' }} />
          <Label font="xs" opacity="50%" margin="0">
            Clientes
          </Label>
        </button>
      )}
      {location.pathname === '/orders' ? (
        <button onClick={goToOrders}>
          <MotorcycleIcon style={{ color: '#1A73E8' }} />
          <Label font="xs" margin="0" color="#1A73E8">
            Ordenes
          </Label>
        </button>
      ) : (
        <button onClick={goToOrders}>
          <MotorcycleIcon style={{ opacity: '50%' }} />
          <Label font="xs" opacity="50%" margin="0">
            Ordenes
          </Label>
        </button>
      )}
    </NavBarContainer>
  );
};

export default withRouter(NavBar);
