import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, Label, ImageContainer, Image } from '../../components';
import { SuccessContainer } from './components';

const Success = props => {
  const { text, buttonText, link, imageWidth, history } = props;

  const handleClick = () => {
    redirect();
  };

  const redirect = () => {
    history.push(link);
  };

  return (
    <SuccessContainer color="#1A73E8">
      <ImageContainer margin="50px 0 0 0">
        <Image name="doneAll" width={imageWidth} />
      </ImageContainer>
      <Label color="white" font="lg" textAlign="center">
        {text}
      </Label>
      <Button color="white" fontColor="#1A73E8" onClick={handleClick}>
        {buttonText}
      </Button>
    </SuccessContainer>
  );
};

Success.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  imageWidth: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default withRouter(Success);
