/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      name
      state
      country
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      name
      state
      country
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      name
      state
      country
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      phone_number
      address
      suburb
      zipCode
      reference
      name
      affiliation_date
      orders {
        items {
          id
          creation_date
          amount
          status
          delivery_date
          delivery_hour
          note_title
          note_content
        }
        nextToken
      }
      city {
        id
        name
        state
        country
      }
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      phone_number
      address
      suburb
      zipCode
      reference
      name
      affiliation_date
      orders {
        items {
          id
          creation_date
          amount
          status
          delivery_date
          delivery_hour
          note_title
          note_content
        }
        nextToken
      }
      city {
        id
        name
        state
        country
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      phone_number
      address
      suburb
      zipCode
      reference
      name
      affiliation_date
      orders {
        items {
          id
          creation_date
          amount
          status
          delivery_date
          delivery_hour
          note_title
          note_content
        }
        nextToken
      }
      city {
        id
        name
        state
        country
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      creation_date
      amount
      status
      delivery_date
      delivery_hour
      note_title
      note_content
      client {
        id
        phone_number
        address
        suburb
        zipCode
        reference
        name
        affiliation_date
        orders {
          nextToken
        }
        city {
          id
          name
          state
          country
        }
      }
      products {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      creation_date
      amount
      status
      delivery_date
      delivery_hour
      note_title
      note_content
      client {
        id
        phone_number
        address
        suburb
        zipCode
        reference
        name
        affiliation_date
        orders {
          nextToken
        }
        city {
          id
          name
          state
          country
        }
      }
      products {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      creation_date
      amount
      status
      delivery_date
      delivery_hour
      note_title
      note_content
      client {
        id
        phone_number
        address
        suburb
        zipCode
        reference
        name
        affiliation_date
        orders {
          nextToken
        }
        city {
          id
          name
          state
          country
        }
      }
      products {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
    }
  }
`;
export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      productItems
      order {
        id
        creation_date
        amount
        status
        delivery_date
        delivery_hour
        note_title
        note_content
        client {
          id
          phone_number
          address
          suburb
          zipCode
          reference
          name
          affiliation_date
        }
        products {
          nextToken
        }
      }
      product {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const updateOrderProduct = /* GraphQL */ `
  mutation UpdateOrderProduct(
    $input: UpdateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    updateOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      productItems
      order {
        id
        creation_date
        amount
        status
        delivery_date
        delivery_hour
        note_title
        note_content
        client {
          id
          phone_number
          address
          suburb
          zipCode
          reference
          name
          affiliation_date
        }
        products {
          nextToken
        }
      }
      product {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const deleteOrderProduct = /* GraphQL */ `
  mutation DeleteOrderProduct(
    $input: DeleteOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    deleteOrderProduct(input: $input, condition: $condition) {
      id
      orderId
      productId
      productItems
      order {
        id
        creation_date
        amount
        status
        delivery_date
        delivery_hour
        note_title
        note_content
        client {
          id
          phone_number
          address
          suburb
          zipCode
          reference
          name
          affiliation_date
        }
        products {
          nextToken
        }
      }
      product {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      price
      quantity
      items
      category {
        id
        type
        products {
          nextToken
        }
      }
      orders {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
      categories {
        items {
          id
          categoryId
          productId
        }
        nextToken
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      price
      quantity
      items
      category {
        id
        type
        products {
          nextToken
        }
      }
      orders {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
      categories {
        items {
          id
          categoryId
          productId
        }
        nextToken
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      price
      quantity
      items
      category {
        id
        type
        products {
          nextToken
        }
      }
      orders {
        items {
          id
          orderId
          productId
          productItems
        }
        nextToken
      }
      categories {
        items {
          id
          categoryId
          productId
        }
        nextToken
      }
    }
  }
`;
export const createCategoryProduct = /* GraphQL */ `
  mutation CreateCategoryProduct(
    $input: CreateCategoryProductInput!
    $condition: ModelCategoryProductConditionInput
  ) {
    createCategoryProduct(input: $input, condition: $condition) {
      id
      categoryId
      productId
      category {
        id
        type
        products {
          nextToken
        }
      }
      product {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const updateCategoryProduct = /* GraphQL */ `
  mutation UpdateCategoryProduct(
    $input: UpdateCategoryProductInput!
    $condition: ModelCategoryProductConditionInput
  ) {
    updateCategoryProduct(input: $input, condition: $condition) {
      id
      categoryId
      productId
      category {
        id
        type
        products {
          nextToken
        }
      }
      product {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const deleteCategoryProduct = /* GraphQL */ `
  mutation DeleteCategoryProduct(
    $input: DeleteCategoryProductInput!
    $condition: ModelCategoryProductConditionInput
  ) {
    deleteCategoryProduct(input: $input, condition: $condition) {
      id
      categoryId
      productId
      category {
        id
        type
        products {
          nextToken
        }
      }
      product {
        id
        name
        price
        quantity
        items
        category {
          id
          type
        }
        orders {
          nextToken
        }
        categories {
          nextToken
        }
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      type
      products {
        items {
          id
          categoryId
          productId
        }
        nextToken
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      type
      products {
        items {
          id
          categoryId
          productId
        }
        nextToken
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      type
      products {
        items {
          id
          categoryId
          productId
        }
        nextToken
      }
    }
  }
`;
