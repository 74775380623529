const listOrders = `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        delivery_date
        status
        amount
        client {
          id
          name
          city {
            id
            name
          }
        }
      }
    }
  }
`;

export default listOrders;
