const getClient = `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      phone_number
      address
      suburb
      zipCode
      reference
      name
      affiliation_date
      orders {
        items {
          id
          creation_date
          amount
          status
          delivery_date
          delivery_hour
          note_title
          note_content
        }
        nextToken
      }
      city {
        id
        name
        state
        country
      }
    }
  }
`;

export default getClient;
