import React from 'react';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';

const StyledSeachBarContainer = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: ${props => props.theme.font.sm};
  padding: 0 30px;
  margin-bottom: 10px;
  box-shadow: 0px 0.5px 3px 0px #0000001a;
  height: 50px;
  width: 100%;

  svg {
    margin-right: 10px;
  }

  input {
    border: none;
    outline: none;
    height: 100%;
    padding: 0;
  }
`;

const SearchBar = props => {
  const { handleChange } = props;
  return (
    <StyledSeachBarContainer>
      <SearchIcon fontSize="small" style={{ opacity: '50%' }} />
      <input placeholder="Buscar por nombre" onChange={handleChange} />
    </StyledSeachBarContainer>
  );
};

export default SearchBar;
