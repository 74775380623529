import React, { Fragment, useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

import logoImage from '../../static/logoImage.jpeg';
import listCities from './graphql/listCities';
import {
  Container,
  HorizontalContainer,
  RoundButton,
  Label,
  Card,
  UserIcon,
  StickyContainer,
  Menu,
  NoDataMessage,
  Loading,
} from '../../components';
import { CardsContainer } from './components';

const Cities = props => {
  const { history } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [cities, setCities] = useState([]);
  const [status, setStatus] = useState('idle');
  const isLoading = status === 'pending';

  useEffect(() => {
    const fetchCities = async () => {
      setStatus('pending');

      try {
        const { data } = await API.graphql(
          graphqlOperation(listCities, { limit: 999 })
        );
        const { items: cities } = data.listCitys;

        setCities(cities);
        setStatus('resolved');
      } catch (error) {
        setStatus('rejected');
      }
    };

    fetchCities();
  }, []);

  const goToClients = city => {
    const { id, name } = city;
    window.localStorage.setItem('cityId', id);
    window.localStorage.setItem('cityName', name);
    history.push('./clients');
  };

  const createNewCity = () => {
    history.push('./create-city');
  };

  const goToAdminProfile = () => {
    history.push('./admin-profile');
  };

  const openMenu = () => {
    setShowMenu(prevState => !prevState);
  };

  return (
    <Fragment>
      {showMenu && <Menu setShowMenu={setShowMenu} />}
      <StickyContainer>
        <HorizontalContainer>
          <RoundButton color="secondary" onClick={openMenu}>
            Menu
          </RoundButton>
          <UserIcon
            width="32px"
            height="32px"
            imgWidth="20px"
            imgHeight="17px"
            onClick={goToAdminProfile}
          >
            <img alt="User" src={logoImage} />
          </UserIcon>
        </HorizontalContainer>
        <HorizontalContainer>
          <Label font="md">Ciudades</Label>
          <RoundButton color="primary" onClick={createNewCity}>
            <AddIcon fontSize="small" /> Ciudad
          </RoundButton>
        </HorizontalContainer>
      </StickyContainer>
      <Container
        color="#F3F4FB"
        minHeight={cities.length ? '100vh' : 'calc(100vh - 129px)'}
      >
        <CardsContainer>
          {isLoading && <Loading />}
          {cities.length > 0 &&
            cities.map(city => {
              return (
                <Card
                  width="100%"
                  height="111px"
                  margin="10px 0 0 0"
                  key={city.id}
                  padding="0 28px"
                  onClick={() => goToClients(city)}
                >
                  <Label font="lg" margin="0 0 6px 0" justify="flex-start">
                    {city.name}
                  </Label>
                </Card>
              );
            })}
          {cities.length === 0 && !isLoading && (
            <NoDataMessage>
              <div className="icon-container">
                <HomeWorkIcon style={{ opacity: '50%' }} />
              </div>
              <Label font="md" opacity="50%" textAlign="center">
                No se han creado ciudades
              </Label>
            </NoDataMessage>
          )}
        </CardsContainer>
      </Container>
    </Fragment>
  );
};
export default withRouter(Cities);
