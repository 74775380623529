import React from 'react';
import PropTypes from 'prop-types';

import { Money } from '../../../../types';
import { ProductsContainer } from './components';
import { Modal, Label } from '../../../../components';

const ProductsModal = props => {
  const { products, numberOfProducts, setShowProductsModal } = props;

  const handleCloseModal = () => {
    setShowProductsModal(false);
  };

  return (
    <Modal
      title={numberOfProducts ? `${numberOfProducts} productos` : 'productos'}
      subTitle="Productos"
      handleClose={handleCloseModal}
      saveButton={false}
    >
      <ProductsContainer>
        {products.map(product => {
          return (
            <div className="product-row" key={product.id}>
              <div className="row">
                <Label font="s" margin="0">
                  {product.name}
                </Label>
                <Label font="xxs" margin="0" className="product-price">
                  {new Money(product.price).format()}
                </Label>
              </div>
              <div className="row">
                <Label font="sm" align="left" margin="0" opacity="50%">
                  {product.quantity}
                </Label>
                <Label font="sm" margin="0" opacity="50%">
                  {product.items}
                </Label>
              </div>
            </div>
          );
        })}
      </ProductsContainer>
    </Modal>
  );
};

ProductsModal.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  numberOfProducts: PropTypes.number,
  setShowProductsModal: PropTypes.func,
};

export default ProductsModal;
