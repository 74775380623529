import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import TodayIcon from '@material-ui/icons/Today';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PlaceIcon from '@material-ui/icons/Place';
import NoteIcon from '@material-ui/icons/Note';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import WhatshotIcon from '@material-ui/icons/Whatshot';

import {
  Container,
  HorizontalContainer,
  RoundButton,
  Label,
  Card,
  UserIcon,
} from '../../components';
import {
  NotesModal,
  ProductsModal,
  StatusModal,
  AddressModal,
} from './components';
import logoImage from '../../static/logoImage.jpeg';
import getOrder from './graphql/getOrder';
import { Money, DateUserFormatter, StatusFormatter } from '../../types';

const OrderDetailsDistributor = props => {
  const { history } = props;
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [order, setOrder] = useState({});
  const [status, setStatus] = useState('');
  const orderId = window.localStorage.getItem('orderId');

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const { data } = await API.graphql(
          graphqlOperation(getOrder, { id: orderId })
        );

        if (data) {
          setOrder(data.getOrder);
          setStatus(data.getOrder.status);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrder();
  }, [status, orderId]);

  const goToOrdersDistributor = () => {
    history.push('./orders-distributor');
  };

  return (
    <Container padding="0" minHeight="100vh">
      <HorizontalContainer padding="0 17px">
        <Label font="s" margin="0">
          Detalles de pedido
        </Label>
        <RoundButton color="secondary" onClick={goToOrdersDistributor}>
          Regresar
        </RoundButton>
      </HorizontalContainer>
      <Card width="100%" margin="10px 0 0 0">
        <div className="card-top no-border">
          <UserIcon width="32px" height="32px" imgWidth="20px" imgHeight="17px">
            <img alt="User" src={logoImage} />
          </UserIcon>
          <Label font="s" margin="0 0 0 6px">
            {Object.keys(order).length && order.client.name}
          </Label>
        </div>
        <div className="order-row">
          <div className="row-label">
            <TodayIcon style={{ opacity: '50%' }} fontSize="small" />
            <Label font="sm" opacity="50%" margin="0 0 0 10px">
              Fecha de entrega
            </Label>
          </div>
          <button>
            {Object.keys(order).length &&
              new DateUserFormatter(order.delivery_date).format()}
          </button>
        </div>
        <div className="order-row">
          <div className="row-label">
            <AccessTimeIcon style={{ opacity: '50%' }} fontSize="small" />
            <Label font="sm" opacity="50%" margin="0 0 0 10px">
              Horario
            </Label>
          </div>
          <button>{Object.keys(order).length && order.delivery_hour}</button>
        </div>
        <div className="order-row">
          <div className="row-label">
            <PlaceIcon style={{ opacity: '50%' }} fontSize="small" />
            <Label font="sm" opacity="50%" margin="0 0 0 10px">
              Domicilio
            </Label>
          </div>
          <button onClick={() => setShowAddressModal(true)}>Ver</button>
        </div>
        <div className="order-row">
          <div className="row-label">
            <NoteIcon style={{ opacity: '50%' }} fontSize="small" />
            <Label font="sm" opacity="50%" margin="0 0 0 10px">
              Notas
            </Label>
          </div>
          <button
            onClick={() => setShowNotesModal(true)}
            disabled={!order.note_title}
          >
            {order.note_title ? 'Ver notas' : 'No hay notas'}
          </button>
        </div>
        <div className="order-row">
          <div className="row-label">
            <ShoppingCartIcon style={{ opacity: '50%' }} fontSize="small" />
            <Label font="sm" opacity="50%" margin="0 0 0 10px">
              Productos
            </Label>
          </div>
          <button onClick={() => setShowProductsModal(true)}>
            Ver productos
          </button>
        </div>
        <div className="order-row">
          <div className="row-label">
            <MonetizationOnIcon style={{ opacity: '50%' }} fontSize="small" />
            <Label font="sm" opacity="50%" margin="0 0 0 10px">
              Total a cobrar
            </Label>
          </div>
          <Label font="s" margin="0">
            {new Money(order.amount).format()}
          </Label>
        </div>
        <div className="order-row">
          <div className="row-label">
            <WhatshotIcon style={{ opacity: '50%' }} fontSize="small" />
            <Label font="sm" opacity="50%" margin="0 0 0 10px">
              Status
            </Label>
          </div>
          <button onClick={() => setShowStatusModal(true)}>
            {Object.keys(order).length && new StatusFormatter(status).format()}
          </button>
        </div>
      </Card>
      {showNotesModal && (
        <NotesModal
          setShowNotesModal={setShowNotesModal}
          notes={{
            title: order.note_title,
            content: order.note_content,
          }}
        />
      )}
      {showProductsModal && (
        <ProductsModal
          setShowProductsModal={setShowProductsModal}
          products={order.products.items}
          orderId={orderId}
        />
      )}
      {showStatusModal && (
        <StatusModal
          setShowStatusModal={setShowStatusModal}
          setStatus={setStatus}
          orderId={orderId}
        />
      )}
      {showAddressModal && (
        <AddressModal
          setShowAddressModal={setShowAddressModal}
          client={Object.keys(order).length && order.client}
        />
      )}
    </Container>
  );
};

export default withRouter(OrderDetailsDistributor);
