import React, { useState } from 'react';
import PropTypes from 'prop-types';

import errorMessages from '../../../../utils/error-messages';
import { Modal, Input, TextArea } from '../../../../components';

const NotesModal = props => {
  const { setShowNotesModal, setNotes } = props;
  const [noteTitle, setNoteTitle] = useState('');
  const [noteContent, setNoteContent] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleCloseModal = () => {
    setShowNotesModal(false);
  };

  const handleSaveNotes = () => {
    if (noteTitle && noteContent) {
      setNotes({ title: noteTitle, content: noteContent });
      setShowNotesModal(false);
    } else {
      setErrorMessage(errorMessages.incompleteData);
      setShowErrorMessage(true);
    }
  };

  const handleTitleChange = event => {
    const { target = {} } = event;
    const { value } = target;
    setNoteTitle(value);
  };

  const handleNoteChange = event => {
    const { target = {} } = event;
    const { value } = target;
    setNoteContent(value);
  };

  return (
    <Modal
      title={noteTitle || 'Título'}
      subTitle="Notas"
      handleClose={handleCloseModal}
      handleSave={handleSaveNotes}
      errorMessage={errorMessage}
      showError={showErrorMessage}
      saveButton={true}
    >
      <Input
        placeholder="Título"
        margin="0 0 10px 0"
        value={noteTitle}
        onChange={handleTitleChange}
      />
      <TextArea
        placeholder="Nota"
        onChange={handleNoteChange}
        value={noteContent}
      />
    </Modal>
  );
};

NotesModal.propTypes = {
  setShowNotesModal: PropTypes.func,
  setNotes: PropTypes.func,
};

export default NotesModal;
